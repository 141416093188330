import {
  AttachmentsZone,
  AutocompleteInput,
  FormInput,
  FormPageLayout,
  SelectInput,
  DataTableEx,
  FormTabs,
  FormTab,
  CheckboxInput,
} from '@kirz/mui-admin';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HomeCityOutline, HomeVariantOutline, Text } from 'mdi-material-ui';
import React from 'react';
import { useParams } from 'react-router-dom';

import { EstatesTable } from 'components/EstatesTable';
import { getSaleQueueName, HouseTypes } from 'constants/estate';
import { YearQuarters } from 'constants/other';

export function SaleQueueForm() {
  return (
    <>
      <FormInput
        name="serialNumber"
        label="Номер"
        required
        type="number"
        xs={6}
        min={1}
        max={100}
      />
      <CheckboxInput name="isCompleted" label="Дом сдан?" xs={6} />
      <FormInput
        name="releaseYear"
        label="Год сдачи"
        required
        type="number"
        xs={6}
        min={1800}
        max={2999}
        thousandsSeparator=""
      />
      <SelectInput
        name="releaseQuarter"
        label="Квартал сдачи"
        required
        xs={6}
        items={YearQuarters}
      />
      <FormInput
        name="keysYear"
        label="Год передачи ключей"
        required
        type="number"
        xs={6}
        min={1800}
        max={2999}
        thousandsSeparator=""
      />
      <SelectInput
        name="keysQuarter"
        label="Квартал передачи ключей"
        required
        xs={6}
        items={YearQuarters}
      />
    </>
  );
}

export function ResidentialComplex() {
  const routeParams = useParams() as Record<string, string>;
  const residentialComplexId = parseInt(routeParams.id, 10);

  return (
    <FormPageLayout
      source="residentialComplex"
      breadcrumbs={[
        {
          text: 'Жилые комплексы',
          icon: HomeCityOutline,
          href: '/residential-complexes',
        },
        (item) => ({ text: `#${item.id} ${item.name}`, copyOnClick: true }),
      ]}
      breadcrumbsDeps={['name']}
      defaultRoute="/residential-complexes"
      formSubmitterProps={{
        preSubmit: (item) => ({
          ...item,
          floorsNumberTo: item.floorsNumberTo || item.floorsNumberFrom,
        }),
      }}
    >
      <FormTabs
        persistStateMode="query"
        tabs={[
          { id: 'main', label: 'Информация', icon: <Text /> },
          { id: 'estates', label: 'Объекты', icon: <HomeVariantOutline /> },
        ]}
        tabsWrapperStyle={{
          mx: { xs: 0, md: 0 },
        }}
      >
        <FormTab tab="main">
          <FormInput name="name" label="Название" md={4} required />
          <AutocompleteInput
            name="developer"
            label="Застройщик"
            mode="hasura"
            source="residentialComplex"
            preset="suggestion"
            md={4}
          />
          <AutocompleteInput
            name="houseType"
            label="Тип дома"
            options={HouseTypes}
            md={4}
          />
          <FormInput
            name="floorsNumberFrom"
            label="Этажность (от)"
            md={2}
            type="number"
            min={0}
            max={100}
          />
          <FormInput
            name="floorsNumberTo"
            label="Этажность (до)"
            md={2}
            type="number"
            min={0}
            max={100}
          />
          <Grid container xs={12} sx={{ m: 0, mt: 2 }}>
            <Grid md={6}>
              <DataTableEx
                id="residential-complex-addresses-table"
                title="Адреса"
                source="estateAddress"
                columns={[
                  { field: 'address', headerName: 'Значение', flex: 1 },
                ]}
                inline
                selectProps={{
                  filter: {
                    residentialComplexId: { _eq: residentialComplexId },
                  },
                }}
                formTitle={(isNew) =>
                  isNew ? 'Новый адрес' : 'Редактирование адреса'
                }
                formDialogProps={{
                  formSubmitterProps: {
                    preSubmit: (row) => ({ ...row, residentialComplexId }),
                  },
                }}
              >
                <FormInput name="address" label="Адрес" required autoFocus />
              </DataTableEx>
            </Grid>
            <Grid md={6}>
              <DataTableEx
                id="estate-sale-queues-table"
                title="Очереди сдачи"
                source="estateSaleQueue"
                sortBy={{ field: 'serialNumber', sort: 'desc' }}
                columns={[
                  { field: 'serialNumber', headerName: '№', width: 60 },
                  {
                    field: 'release',
                    selector: 'releaseQuarter releaseYear',
                    headerName: 'Год сдачи',
                    flex: 1,
                    valueGetter(params) {
                      return getSaleQueueName(
                        params.row.releaseQuarter,
                        params.row.releaseYear,
                      );
                    },
                    onSort: (sort) => [
                      { releaseYear: sort },
                      { releaseQuarter: sort },
                    ],
                  },
                  {
                    field: 'keys',
                    selector: 'keysQuarter keysYear',
                    headerName: 'Год передачи ключей',
                    flex: 1,
                    valueGetter(params) {
                      return getSaleQueueName(
                        params.row.keysQuarter,
                        params.row.keysYear,
                      );
                    },
                    onSort: (sort) => [
                      { keysYear: sort },
                      { keysQuarter: sort },
                    ],
                  },
                ]}
                inline
                selectProps={{
                  filter: {
                    residentialComplexId: { _eq: residentialComplexId },
                  },
                }}
                formTitle={(isNew) =>
                  isNew ? 'Новая очередь' : 'Редактирование очереди'
                }
                formDialogProps={{
                  formSubmitterProps: {
                    preSubmit: (row) => ({ ...row, residentialComplexId }),
                  },
                }}
              >
                <SaleQueueForm />
              </DataTableEx>
            </Grid>

            <AttachmentsZone
              xs={12}
              title="Файлы"
              name="residentialComplexId"
              entityId={residentialComplexId}
              attachmentsTypes={[
                { value: 'photo', text: 'Фото' },
                { value: 'plan', text: 'План комплекса' },
                { value: 'finishing', text: 'Отделка' },
              ]}
            />
          </Grid>
        </FormTab>
        <FormTab
          tab="estates"
          grid={false}
          sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}
        >
          {(isVisible) =>
            !isVisible ? null : (
              <Box
                sx={{
                  overflow: 'hidden',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <EstatesTable
                  id="residential-complex-estates-table"
                  skeletonRowsCount={50}
                  selectProps={{
                    filter: {
                      residentialComplexId: { _eq: residentialComplexId },
                    },
                  }}
                />
              </Box>
            )
          }
        </FormTab>
      </FormTabs>
    </FormPageLayout>
  );
}
