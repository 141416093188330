import {
  AutocompleteInput,
  FormGetter,
  FormInput,
  FormSetter,
  FormDialog,
  FormDialogProps,
  DateInput,
  ConfigurationContext,
} from '@kirz/mui-admin';
import { InputAdornment } from '@mui/material';
import React, { useContext } from 'react';

import { FunnelStageType, FunnelType, FunnelTypes } from 'constants/funnels';
import { UserContext } from 'contexts';

import { EstateAutocompleteInput } from './EstateAutocompleteInput';

export function NewDealDialog(
  props: Partial<FormDialogProps> & {
    disableClient?: boolean;
    disableEstate?: boolean;
    disableSystemType?: boolean;
    hideClient?: boolean;
    hideSeller?: boolean;
    requireClient?: boolean;
    requireSeller?: boolean;
  },
) {
  const { user } = useContext(UserContext);
  const { hasura } = useContext(ConfigurationContext);
  const {
    disableClient,
    disableEstate,
    disableSystemType,
    hideClient,
    hideSeller,
    requireClient,
    requireSeller,
    ...rest
  } = props;

  return (
    <FormDialog
      {...(rest as any)}
      source={rest.source ?? 'deal'}
      title={rest.title ?? 'Создать сделку'}
      maxWidth={rest.maxWidth ?? 'sm'}
      formProps={{
        ...rest.formProps,
        shouldUnregister: false,
        defaultValues: {
          userId: user.id,
          systemType: FunnelType.DEFAULT,
          ...rest.formProps?.defaultValues,
          funnelId: null,
        },
      }}
      formSubmitterProps={{
        ...rest.formSubmitterProps,
        async preSubmit({ _new, ...item }) {
          return {
            ...item,
            ...(await (async () => {
              if (item.systemType === FunnelType.DEFAULT) {
                return {};
              }

              const funnels = await hasura.request({
                type: 'query',
                source: 'funnel',
                selection:
                  'isDefault id type stages (orderBy:{serialNumber:ASC}) { id type }',
                where: { isRemoved: { _eq: false } },
              });

              const funnel =
                funnels.find((x: any) => x.type === item.systemType) ??
                funnels.find((x: any) => x.isDefault) ??
                funnels[0];

              let funnelStage = funnel.stages.find(
                (x: any) => x.type === FunnelStageType.START,
              );

              const dealStage = funnel.stages.find(
                (x: any) => x.type === FunnelStageType.DEAL,
              );

              if (item.systemType === FunnelType.TECHNICAL && dealStage) {
                funnelStage = dealStage;
              }

              return {
                ...item,
                funnelId: funnel.id,
                funnelStageId: funnelStage.id,
              };
            })()),
          };
        },
      }}
    >
      <FormGetter
        names={['systemType']}
        render={(x) =>
          x.systemType === FunnelType.DEFAULT ? (
            <>
              <FormInput name="name" label="Название" sm={6} />
              <AutocompleteInput
                name="systemType"
                label="Тип"
                sm={6}
                options={FunnelTypes.filter(
                  (x) => x.value !== FunnelType.TECHNICAL,
                )}
                required
                disabled={disableSystemType}
              />
              <AutocompleteInput
                label="Эксперт"
                name="userId"
                mode="hasura"
                source="user"
                selection="id fullName"
                fetchAll={false}
                itemText="fullName"
                required
              />

              <FormSetter
                render={(setValue) => (
                  <>
                    <AutocompleteInput
                      label="Воронка"
                      name="funnelId"
                      mode="hasura"
                      source="funnel"
                      selection="id name"
                      itemText="name"
                      required
                      onFetch={(items) => {
                        setValue(
                          'funnelId',
                          rest.formProps?.defaultValues?.funnelId,
                        );
                        return items;
                      }}
                    />
                    <FormGetter
                      names={['funnelId']}
                      onChange={() => {
                        setValue('funnelStageId', null);
                      }}
                      render={({ funnelId }) => {
                        return (
                          <AutocompleteInput
                            label="Этап"
                            name="funnelStageId"
                            mode="hasura"
                            source="funnelStage"
                            selection="id name"
                            itemText="name"
                            required
                            disabled={!funnelId}
                            disableFetch={!funnelId}
                            orderBy={[{ serialNumber: 'ASC' }]}
                            filter={{
                              funnelId: { _eq: funnelId },
                              isRemoved: { _eq: false },
                            }}
                          />
                        );
                      }}
                    />
                  </>
                )}
              />

              {!hideSeller && (
                <AutocompleteInput
                  label="Продавец"
                  name="sellerId"
                  mode="hasura"
                  source="client"
                  selection="id fullName"
                  fetchAll={false}
                  itemText="fullName"
                />
              )}
              {!hideClient && (
                <AutocompleteInput
                  label="Покупатель"
                  name="clientId"
                  mode="hasura"
                  source="client"
                  selection="id fullName"
                  fetchAll={false}
                  itemText="fullName"
                />
              )}
              <FormGetter
                names={['sellerId']}
                render={({ sellerId }) => (
                  <EstateAutocompleteInput
                    showOpenInNew={false}
                    disabled={disableEstate}
                    {...({ sellerId } as any)}
                  />
                )}
              />
            </>
          ) : x.systemType === FunnelType.RENT ? (
            <>
              <FormInput name="name" label="Название" sm={6} />
              <AutocompleteInput
                name="systemType"
                label="Тип"
                sm={6}
                options={FunnelTypes.filter(
                  (x) => x.value !== FunnelType.TECHNICAL,
                )}
                required
                disabled={disableSystemType}
              />
              <DateInput name="rentDeliveryDate" label="Дата сдачи" required />
              <FormInput
                name="rentContractTerm"
                label="Срок договора"
                required
              />
              <FormInput
                name="rentFee"
                type="number"
                label="Арендная плата"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">₽</InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            <>
              {!hideSeller && (
                <AutocompleteInput
                  label="Продавец"
                  name="sellerId"
                  mode="hasura"
                  source="client"
                  selection="id fullName"
                  fetchAll={false}
                  itemText="fullName"
                  required={requireSeller}
                />
              )}
              {!hideClient && (
                <AutocompleteInput
                  label="Покупатель"
                  name="clientId"
                  mode="hasura"
                  source="client"
                  selection="id fullName"
                  fetchAll={false}
                  itemText="fullName"
                  required={requireClient}
                />
              )}
              <FormGetter
                names={['sellerId']}
                render={({ sellerId }) => (
                  <EstateAutocompleteInput
                    showOpenInNew={false}
                    required
                    // disabled={disableEstate}
                    {...({ sellerId } as any)}
                  />
                )}
              />
              <DateInput name="dealDate" label="Дата сделки" required />
              <FormInput
                name="dealPrice"
                type="number"
                label="Сумма cделки"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">₽</InputAdornment>
                  ),
                }}
              />
              <FormGetter
                names={['dealPrice']}
                render={(x) => (
                  <FormInput
                    name="dealContractPrice"
                    type="number"
                    label="Сумма cделки по договору"
                    {...(x.dealPrice && {
                      placeholder: x.dealPrice.toLocaleString(),
                      InputLabelProps: { shrink: true },
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₽</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </>
          )
        }
      />
    </FormDialog>
  );
}
