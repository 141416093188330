import {
  AutocompleteInput,
  CheckboxInput,
  ConfigurationContext,
  DateInput,
  FormGetter,
  FormInput,
  FormSetter,
  TimeInput,
  FormDialog,
  FormDialogProps,
  HasuraSelector,
  OpenInNewInputAdorement,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useRef } from 'react';

import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';
import { useDealStageManager } from 'hooks/useDealStageManager';

export function NewTaskDialog(
  props: Partial<FormDialogProps> & {
    disableClient?: boolean;
    disableDeal?: boolean;
    clientOptions?: string[];
  },
) {
  const { validateDealStageChange } = useDealStageManager(false);
  const { user } = useContext(UserContext);
  const { tags } = useContext(SettingsContext);
  const { hasura } = useContext(ConfigurationContext);
  const submittedTagsIds = useRef<number[]>([]);
  const submittedFunnelStageId = useRef<number>();
  const { disableClient, clientOptions, disableDeal, ...rest } = props;

  return (
    <FormDialog
      {...(rest as any)}
      source={rest.source ?? 'task'}
      title={rest.title ?? 'Новая задача'}
      maxWidth="md"
      autoFocus={false}
      formProps={{
        ...props.formProps,
        defaultValues: {
          userId: user.id,
          date: dayjs().format('YYYY-MM-DD'),
          isImportant: false,
          ...props.formProps?.defaultValues,
        },
      }}
      components={{
        ActionButtons(props) {
          // props.cancelButton
          return (
            <>
              {props.cancelButton}
              {props.submitButton}
              <Button
                variant="text"
                sx={{ ml: 'auto' }}
                onClick={async () => {
                  const { tags, funnelStageId, ...item } =
                    props.formRef.current?.getControl()._formValues as any;

                  if (item.dealId === 'none') {
                    item.dealId = null;
                  }

                  submittedTagsIds.current = tags || [];
                  submittedFunnelStageId.current = funnelStageId;

                  if (submittedFunnelStageId.current && item.dealId) {
                    const [[deal], [funnelStage]] = await Promise.all([
                      hasura.request({
                        source: 'deal',
                        type: 'query',
                        where: {
                          id: { _eq: item.dealId },
                        },
                        selection: 'id funnelStage { type }',
                      }),
                      hasura.request({
                        source: 'funnelStage',
                        type: 'query',
                        where: {
                          id: { _eq: submittedFunnelStageId.current },
                        },
                        selection: 'id type',
                        limit: 1,
                      }),
                    ]);

                    const validationResult = await validateDealStageChange(
                      item.dealId,
                      deal.funnelStage.type,
                      funnelStage.type,
                    );

                    if (!validationResult) {
                      return null;
                    }

                    await Promise.all(
                      validationResult.updates.map((x) => hasura.request(x)),
                    );
                  }

                  props.formRef.current?.submit();
                }}
              >
                Создать
              </Button>
            </>
          );
        },
      }}
      formSubmitterProps={{
        ...rest.formSubmitterProps,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        preSubmit: async ({ tags, funnelStageId, ...item }) => {
          return { ...item, creatorId: user.id };
        },
        selection: ['id', 'dealId'],
        async onSubmit({ funnelStageId, ...data }) {
          if (data.dealId === 'none') {
            data.dealId = null;
          }

          const { id: taskId } = data;
          await hasura.request({
            type: 'mutation',
            source: 'taskTag',
            action: 'insert',
            items: [
              ...submittedTagsIds.current.map((tagId) => ({
                tagId,
                taskId,
                companyId: user.companyId,
              })),
            ],
          });

          if (submittedFunnelStageId.current && data.dealId) {
            await hasura.request({
              type: 'mutation',
              source: 'deal',
              action: 'update',
              where: { id: { _eq: data.dealId } },
              set: {
                funnelStageId: submittedFunnelStageId.current,
              },
            });
          }

          submittedTagsIds.current = [];
          if (rest.formSubmitterProps?.onSubmit) {
            await rest.formSubmitterProps?.onSubmit(data);
          }
        },
      }}
    >
      <HasuraSelector
        name="funnelStage"
        selection="deal { funnelId }"
        refetchOnSubmit
        resolveValue={(row) => {
          return {
            ...row,
            funnelStage: row?.deal?.funnelStage,
          };
        }}
      />
      {clientOptions ? (
        <AutocompleteInput
          name="clientId"
          mode="hasura"
          source="client"
          label="Клиент"
          selection="id fullName"
          itemText="fullName"
          md={4}
          required
          filter={{
            id: { _in: clientOptions },
          }}
        />
      ) : (
        <AutocompleteInput
          name="clientId"
          mode="hasura"
          source="client"
          label="Клиент"
          selection="id fullName"
          itemText="fullName"
          md={4}
          required
          fetchAll={false}
          disabled={disableClient}
          inputProps={{
            InputProps: {
              startAdornment: (
                <OpenInNewInputAdorement field="clientId" baseUrl="/clients" />
              ),
            },
          }}
        />
      )}

      <FormSetter
        render={(setValue) => (
          <FormGetter
            names={['clientId']}
            onChange={(newValue, prevValue) => {
              if ((newValue.clientId && !prevValue?.clientId) || disableDeal) {
                return;
              }

              setValue('dealId', null);
            }}
            render={({ clientId }) => (
              <AutocompleteInput
                name="dealId"
                mode="hasura"
                source="deal"
                label="Сделка"
                selection="id name"
                itemText={(x) =>
                  x.id === 'none'
                    ? x.text
                    : `Сделка #${x.id}${x.name ? `: ${x.name}` : ''}`
                }
                md={4}
                fetchAll
                disabled={!clientId}
                filter={{
                  _or: [
                    { clientId: { _eq: clientId } },
                    { sellerId: { _eq: clientId } },
                    { clients: { clientId: { _eq: clientId } } },
                  ],
                }}
                disableFetch={!clientId}
                required={!!clientId}
                onFetch={(items) => {
                  return [...items, { id: 'none', text: 'Нет сделки' }];
                }}
                inputProps={{
                  InputProps: {
                    startAdornment: (
                      <OpenInNewInputAdorement
                        field="dealId"
                        baseUrl="/deals"
                      />
                    ),
                  },
                }}
              />
            )}
          />
        )}
      />
      <FormGetter
        names={['dealId', 'funnelStage']}
        render={({ funnelStage }) =>
          funnelStage && (
            <AutocompleteInput
              name="funnelStageId"
              mode="hasura"
              source="funnelStage"
              filter={{
                funnelId: { _eq: funnelStage.funnelId },
                isRemoved: { _eq: false },
              }}
              label="Этап сделки"
              selection="id name"
              itemText="name"
              required
              md={4}
            />
          )
        }
      />

      <AutocompleteInput
        name="userId"
        mode="hasura"
        source="user"
        label="Исполнитель"
        selection="id fullName"
        itemText="fullName"
        required
        md={4}
      />
      <DateInput name="date" label="Дата" md={3} required />
      <TimeInput name="time" label="Время" md={2} />
      <AutocompleteInput
        name="tags"
        label="Теги"
        multiple
        md={5}
        options={tags
          .filter((x) => !x.systemType && x.entity === 'task')
          .map((x) => ({ value: x.id, text: x.name }))}
      />
      <CheckboxInput name="isImportant" label="Важная?" md={2} />
      <FormInput
        name="task"
        label="Текст задачи"
        required
        multiline
        minRows={3}
      />
    </FormDialog>
  );
}
