import {
  AutocompleteInput,
  CheckboxInput,
  ConditionalInput,
  DateInput,
  FileInput,
  FormGetter,
  FormInput,
  FormTab,
  HasuraSelector,
  HiddenInput,
  NotificationsContext,
} from '@kirz/mui-admin';
import { WarningOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Reload } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';

import { ComputedField } from 'components/ComputedField';
import { DealContractForms } from 'constants/deal';
import { PaymentFormNotes, PaymentForms } from 'constants/estate';
import { AmountInput, PercentInput } from 'shared/inputs';

import PriceTabCommissionsSection from './PriceTabCommissionsSection';
import { computedFieldInputProps } from './shared/computedFieldInputProps';

export const DealBookingInputs = (
  <Grid container sx={{ m: 0 }}>
    <DateInput label="Дата" name="prepaymentDate" required />
    <FormInput label="Сумма" name="prepaymentSum" required type="number" />
    <FormInput
      label="Комментарий"
      name="prepaymentComment"
      multiline
      rows={2}
    />
    <FileInput label="Файл" name="prepaymentFileId" />
  </Grid>
);

export default function PriceTab({ dealId }: { dealId: number }) {
  const { showAlert } = useContext(NotificationsContext);
  const [isEstateLoaded, setIsEstateLoaded] = useState<boolean | null>(null);

  const priceInfoFields = [
    'purchasePrice',
    'contractPurchasePrice',
    'purchaseDate',
    'tax',
    'totalIncome',
    'yearlyIncome',
    'computedSellerReceivedSum',
  ];

  return (
    <FormTab tab="price" grid={false}>
      <HiddenInput name="fixedSalaries" formSubmitterValueResolver={null} />
      <HiddenInput name="isFixed" formSubmitterValueResolver={null} />
      <HasuraSelector
        refetchOnSubmit
        name={priceInfoFields}
        selection={`price { ${priceInfoFields.join(' ')} }`}
        resolveValue={({ price }) => {
          setIsEstateLoaded(!!price);
          return price;
        }}
      />
      <FormGetter
        names={['isFixed']}
        render={({ isFixed }: { isFixed: boolean }) => (
          <>
            {!!isFixed && (
              <Alert color="info" sx={{ mb: 2 }}>
                Сделка завершена, значения стоимостей зафиксированы и недоступны
                для редактирования
              </Alert>
            )}
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Общая информация
            </Typography>
            <Grid container sx={{ m: 0 }}>
              <FormInput
                name="contractNumber"
                label="Номер договора"
                md={3}
                lg={3}
              />
              <FormInput
                name="contractArea"
                label="Метраж по договору"
                type="number"
                md={3}
                lg={3}
              />
              <DateInput
                name="ownershipDate"
                label="Дата получения собственности"
                md={3}
                lg={3}
              />
              <AutocompleteInput
                name="contractForm"
                label="Форма договора"
                options={DealContractForms}
                md={3}
              />

              <FormInput
                label="Сумма сделки"
                name="dealPrice"
                type="number"
                {...AmountInput}
                md={3}
                readOnly={!!isFixed}
              />
              <FormGetter
                names={['dealPrice']}
                render={(x) => (
                  <FormInput
                    label="Сумма сделки по договору"
                    name="dealContractPrice"
                    type="number"
                    {...AmountInput}
                    {...(x.dealPrice && {
                      placeholder: x.dealPrice.toLocaleString(),
                      InputLabelProps: { shrink: true },
                    })}
                    md={3}
                    readOnly={!!isFixed}
                  />
                )}
              />
              <FormInput
                label="Сумма предыдущей сделки"
                name="purchasePrice"
                type="number"
                formSubmitterValueResolver={null}
                formFetcherValueResolver={null}
                {...AmountInput}
                md={3}
                readOnly
              />
              <FormGetter
                names={['purchasePrice']}
                render={(x) => (
                  <FormInput
                    label="Сумма предыдущей сделки по договору"
                    name="contractPurchasePrice"
                    type="number"
                    formSubmitterValueResolver={null}
                    {...AmountInput}
                    formFetcherValueResolver={null}
                    {...(x.purchasePrice && {
                      placeholder: x.purchasePrice.toLocaleString(),
                      InputLabelProps: { shrink: true },
                    })}
                    md={3}
                    readOnly
                  />
                )}
              />
              <DateInput
                label="Дата предыдущей сделки"
                name="purchaseDate"
                formSubmitterValueResolver={null}
                formFetcherValueResolver={null}
                md={3}
                readOnly
              />

              <AutocompleteInput
                label="Форма оплаты"
                name="paymentForm"
                options={PaymentForms}
                md={3}
                readOnly={!!isFixed}
              />
              <AutocompleteInput
                label="Примечание к форме оплаты"
                name="paymentFormNote"
                multiple
                options={PaymentFormNotes}
                md={6}
                lg={3}
                readOnly={!!isFixed}
              />

              <Grid container xs={12} sx={{ m: 0, mt: 3 }}>
                <Grid xs={12}>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Доходность
                  </Typography>
                </Grid>
                <ComputedField
                  name="tax"
                  label="Налог"
                  {...computedFieldInputProps()}
                />
                <ComputedField
                  name="totalIncome"
                  label="Общий доход"
                  {...computedFieldInputProps()}
                />
                <ComputedField
                  name="yearlyIncome"
                  label="Годовой доход"
                  {...computedFieldInputProps(PercentInput)}
                />
              </Grid>

              <Grid container xs={12} sx={{ m: 0, mt: 3 }}>
                <Grid xs={12} md="auto">
                  <Box sx={{ maxWidth: 680 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      Комиссии
                    </Typography>
                    {isEstateLoaded === true || isEstateLoaded === null ? (
                      <PriceTabCommissionsSection />
                    ) : (
                      <Box sx={{ display: 'flex', pl: 2 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16,
                            opacity: 0.7,
                          }}
                        >
                          <WarningOutlined sx={{ mr: 1 }} color="warning" />
                          Объект не заполнен
                        </Typography>
                        <Button
                          sx={{ ml: 2 }}
                          startIcon={<Reload />}
                          onClick={async () => {
                            window.location.reload();
                            showAlert('Обновлено');
                          }}
                        >
                          Обновить
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      Аванс
                    </Typography>
                    <Box sx={{ mt: -2 }}>
                      <CheckboxInput
                        name="isPrepaymentDone"
                        sx={{ ml: 2 }}
                        label="Внесен?"
                      />
                    </Box>
                  </Box>
                  <ConditionalInput
                    deps={['isPrepaymentDone']}
                    variants={[
                      {
                        if: (x) => x.isPrepaymentDone,
                        input: () => {
                          return DealBookingInputs;
                        },
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      />
    </FormTab>
  );
}
