import { FormTab } from '@kirz/mui-admin';
import React from 'react';

import { CallsTable } from 'components/CallsTable';

export default function CallsTab(props: { dealId: number }) {
  const { dealId } = props;

  return (
    <FormTab tab="calls" grid={false} sx={{ flexGrow: 1, display: 'flex' }}>
      {(isVisible) =>
        !isVisible ? null : (
          <CallsTable
            id="deal-calls-table"
            sx={{ mx: -2, borderColor: 'transparent' }}
            filterColumns={(columns) =>
              columns.filter((column) => column.field !== 'dealId')
            }
            selectProps={{
              filter: { dealId: { _eq: dealId } },
            }}
          />
        )
      }
    </FormTab>
  );
}
