import { FormInput, FormTab } from '@kirz/mui-admin';
import { Masonry } from '@mui/lab';
import React from 'react';

import { Permissions } from 'constants/permissions';

import BooleanPermission from '../RoleBooleanPermission';
import RoleSection from '../RoleSection';

export function PermissionsTab() {
  return (
    <FormTab
      tab="permissions"
      grid={false}
      sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
    >
      <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={2}>
        <RoleSection title="Основная информация">
          <FormInput name="name" label="Название" required />
        </RoleSection>
        <RoleSection title="Задачи">
          <BooleanPermission
            name={Permissions.TASKS_ENABLED}
            label={'Раздел "Задачи"'}
          />
          <BooleanPermission
            when={[Permissions.TASKS_ENABLED]}
            name={Permissions.TASKS_VIEW_ALL}
            label="Отображать все задачи"
          />
          <BooleanPermission
            name={Permissions.TASKS_EDIT_DATE}
            label="Изменение дедлайна задач"
          />
          <BooleanPermission
            name={Permissions.TASKS_EDIT_NOT_YOURS_TASK}
            label="Редактирование чужой задачи"
          />
          <BooleanPermission
            name={Permissions.TASKS_DELETE}
            label="Удаление задачи"
          />
        </RoleSection>

        <RoleSection title="Сделки">
          <BooleanPermission
            name={Permissions.DEALS_ENABLED}
            label={'Раздел "Сделки"'}
          />
          <BooleanPermission
            when={[Permissions.DEALS_ENABLED]}
            name={Permissions.DEALS_VIEW_ALL}
            label="Отображать все сделки"
          />
          <BooleanPermission
            name={Permissions.DEALS_REPORT_VIEW}
            label="Доступ в отчет"
          />
          <BooleanPermission
            name={Permissions.DEALS_EDIT_RECEIVED_COMMISSION}
            label="Редактирование полученной комиссии"
          />
          <BooleanPermission
            name={Permissions.DEALS_CANCEL_FINAL_STAGE}
            label="Перенос сделки из финального этапа"
          />
          <BooleanPermission
            name={Permissions.DEALS_DELETE}
            label="Удаление сделки"
          />
        </RoleSection>

        <RoleSection title="Лиды">
          <BooleanPermission
            name={Permissions.LEADS_ENABLED}
            label={'Раздел "Лиды"'}
          />
        </RoleSection>

        <RoleSection title="Клиенты">
          <BooleanPermission
            name={Permissions.CLIENTS_ENABLED}
            label={'Раздел "Клиенты"'}
          />
          <BooleanPermission
            when={[Permissions.CLIENTS_ENABLED]}
            name={Permissions.CLIENTS_VIEW_ALL}
            label="Отображать всех клиентов"
          />
          <BooleanPermission
            when={[
              Permissions.CLIENTS_ENABLED,
              { name: Permissions.CLIENTS_VIEW_ALL, condition: (x) => !x },
            ]}
            name={Permissions.CLIENTS_VIEW_ALL_SEARCH}
            label="Отображать всех клиентов при поиске (3+ символов)"
          />
          <BooleanPermission
            when={[Permissions.CLIENTS_ENABLED]}
            name={Permissions.CLIENTS_ARCHIVE}
            label="Доступ в архив удаленных"
          />
        </RoleSection>

        <RoleSection title="Объекты">
          <BooleanPermission
            name={Permissions.ESTATES_ENABLED}
            label={'Раздел "Объекты"'}
          />
          <BooleanPermission
            when={[Permissions.ESTATES_ENABLED]}
            name={Permissions.ESTATES_VIEW_ALL}
            label="Отображать все объекты"
          />
          <BooleanPermission
            when={[
              Permissions.ESTATES_ENABLED,
              { name: Permissions.ESTATES_VIEW_ALL, condition: (x) => !x },
            ]}
            name={Permissions.ESTATES_VIEW_ALL_SEARCH}
            label="Отображать все объекты при поиске (3+ символов)"
          />
          <BooleanPermission
            when={[Permissions.ESTATES_ENABLED]}
            name={Permissions.ESTATES_ARCHIVE}
            label="Доступ в архив удаленных"
          />
        </RoleSection>

        <RoleSection title="Жилые комплексы">
          <BooleanPermission
            name={Permissions.RESIDENTIAL_COMPLEXES_ENABLED}
            label={'Раздел "Жилые комплексы"'}
          />
        </RoleSection>

        <RoleSection title="Звонки">
          <BooleanPermission
            name={Permissions.CALLS_ENABLED}
            label={'Раздел "Звонки"'}
          />
          <BooleanPermission
            when={[Permissions.CALLS_ENABLED]}
            name={Permissions.CALLS_VIEW_ALL}
            label="Отображать все звонки"
          />
        </RoleSection>

        <RoleSection title="Статистика">
          <BooleanPermission
            name={Permissions.ANALYZE_STATISTICS}
            label={'Раздел "Статистика"'}
          />
          <BooleanPermission
            when={[Permissions.ANALYZE_STATISTICS]}
            name={Permissions.ANALYZE_STATISTICS_VIEW_ALL}
            label="Отображать статистику по всем пользователям"
          />
          <BooleanPermission
            name={Permissions.ANALYZE_VIEW_SALARIES}
            label={'Раздел "Зарплата"'}
          />
        </RoleSection>

        <RoleSection title="Статистика">
          <BooleanPermission
            name={Permissions.ANALYZE_DATABASE_QUERY}
            label={'Раздел "Выборка"'}
          />
          <BooleanPermission
            name={Permissions.ANALYZE_DATABASE_QUERY_MANAGEMENT}
            label="Создание/редактирование/удаление запросов"
          />
        </RoleSection>

        <RoleSection title="Отчеты">
          <BooleanPermission
            name={Permissions.REPORTS_MONTH_LEADS}
            label="Список лидов за месяц"
          />
          <BooleanPermission
            name={Permissions.REPORTS_CONVERSIONS}
            label="Конверсия лидов ОП"
          />
        </RoleSection>

        <RoleSection title="Настройки">
          <BooleanPermission
            name={Permissions.SETTINGS_ROLES_FULL}
            label="Управление ролями"
          />
          <BooleanPermission
            name={Permissions.SETTINGS_USERS_FULL}
            label="Управление пользователями"
          />
          <BooleanPermission
            name={Permissions.SETTINGS_FUNNELS_FULL}
            label="Управление воронками"
          />
          <BooleanPermission
            name={Permissions.SETTINGS_DOCUMENTS_FULL}
            label="Управление договорами"
          />
          <BooleanPermission
            name={Permissions.SETTINGS_FORMULES_FULL}
            label="Управление формулами"
          />
          <BooleanPermission
            name={Permissions.SETTINGS_PARAMETERS_FULL}
            label="Управление параметрами"
          />
        </RoleSection>
      </Masonry>
    </FormTab>
  );
}
