import {
  DataTable,
  HasuraDataTableColumnDef,
  TablePageLayout,
  useNavigate,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { DeleteRestore } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { NewClientDialog } from 'components/NewClientDialog';
import { TagsGroup } from 'components/TagsGroup';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export function Clients() {
  const { user, hasPermission } = useContext(UserContext);
  const { tags, leadSources } = useContext(SettingsContext);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isArchivedSelected, setIsArchivedSelected] = useState<boolean>(false);

  const navigate = useNavigate();
  const tableRef = useRef<DataTableExRef>(null);
  const viewAchive = hasPermission(Permissions.CLIENTS_ARCHIVE);
  const viewAllClients = hasPermission(Permissions.CLIENTS_VIEW_ALL);
  const viewAllClientsSearch = hasPermission(
    Permissions.CLIENTS_VIEW_ALL_SEARCH,
  );

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'fullName', headerName: 'ФИО', flex: 1 },
      {
        field: 'favouritePhone',
        headerName: 'Телефон',
        type: 'phone',
        flex: 1,
      },
      {
        field: 'favouriteEmail',
        headerName: 'E-mail',
        type: 'email',
        flex: 1,
      },
      {
        field: 'passport',
        headerName: 'Паспорт',
        selector: 'passportNumber passportSeries',
        flex: 1,
        hidden: true,
        valueGetter({ row }) {
          return `${row.passportSeries ?? ''} ${
            row.passportNumber ?? ''
          }`.trim();
        },
        onSort(sort) {
          return [{ passportSeries: sort }, { passportNumber: sort }];
        },
      },
      {
        field: 'source',
        headerName: 'Источник',
        flex: 1,
        hidden: true,
      },
      {
        field: 'createdAt',
        headerName: 'Дата создания',
        type: 'date',
        flex: 1,
        hidden: true,
      },
      {
        field:
          'estatesAggregate (where: { estate: { isRemoved:{ _eq: false } } }) { aggregate { *count* } }',
        headerName: 'Число объектов',
        type: 'relationship',
        fetchRemoved: false,
        flex: 1,
        hidden: true,
        sortable: false,
        onSort(sort) {
          return [{ estatesAggregate: { count: sort } }];
        },
      },
      {
        field: 'tags',
        headerName: 'Теги',
        width: 100,
        selector: 'tags { tagId }',
        sortable: false,
        renderCell(params) {
          return <TagsGroup ids={params.value.map((x: any) => x.tagId)} />;
        },
      },
      {
        field: 'user',
        headerName: 'Эксперт',
        type: 'relationship',
        selector: 'user { *shortFullName* }',
        flex: 1,
      },
      ...(viewAchive
        ? [
            {
              field: 'recover',
              type: 'icon-button',
              icon: <DeleteRestore />,
              tooltip: 'Восстановить',
              selector: false,
              tabs: ['archived'],
              onClick: async (
                row,
                {
                  configuration: { hasura },
                  notifications: { showConfirm, showAlert },
                },
              ) => {
                if (
                  !(await showConfirm({
                    title: 'Восстановить клиента?',
                    accept: 'Восстановить',
                    cancel: 'Отмена',
                  }))
                ) {
                  return;
                }

                await hasura.request({
                  type: 'mutation',
                  action: 'update',
                  source: 'client',
                  where: { id: { _eq: row.id } },
                  set: { isRemoved: false },
                });

                showAlert('Восстановлено', 'success');
                await tableRef.current!.reload();
              },
            } as HasuraDataTableColumnDef,
          ]
        : []),
    ],
    [viewAchive],
  );

  return (
    <TablePageLayout
      title="Клиенты"
      {...(viewAchive && {
        paddingTop: 1,
      })}
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setIsEditModalOpened(true);
          }}
        >
          Добавить
        </Button>
      }
    >
      <DataTable
        id="clients-table"
        ref={tableRef}
        mode="hasura"
        source="client"
        columns={columns}
        editable={{
          link: (row) => `/clients/${row.id}`,
        }}
        disableRemovedFilter={viewAchive}
        skeletonRowsCount={50}
        deletable={!isArchivedSelected}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        {...(viewAchive && {
          tabsFilter: {
            tabs: [
              {
                id: 'active',
                label: 'Все',
                filter: { isRemoved: { _eq: false } },
              },
              {
                id: 'archived',
                label: 'Удаленные',
                filter: { isRemoved: { _eq: true } },
              },
            ],
            tabsProps: {
              onChange(e, value) {
                setIsArchivedSelected(value === 'archived');
              },
            },
          },
        })}
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по ID, телефону, email или ФИО',
          },
          minLength: 0,
          filter: (search) => {
            const phoneSearchStr = search[0]?.replace(/\D/g, '');
            const phoneSearch = phoneSearchStr
              ? phoneSearchStr.length === 4
                ? { phone: { _ilike: `%${phoneSearchStr}` } }
                : phoneSearchStr.length > 7
                ? { phone: { _ilike: `%${phoneSearchStr}%` } }
                : null
              : null;

            console.log({
              phoneSearchStr,
              phoneSearch,
              search,
            });
            return {
              _and: [
                ...(!viewAllClients &&
                (!viewAllClientsSearch || search[0].length < 3)
                  ? [
                      {
                        participants: {
                          userId: { _eq: user.id },
                        },
                      },
                    ]
                  : []),
                {
                  _or: [
                    ...search.flatMap((str) => [
                      ...(Number.isInteger(parseInt(str, 10)) && str.length < 7
                        ? [{ id: { _eq: parseInt(str, 10) } }]
                        : []),
                      { fullName: { _ilike: `%${str}%` } },
                    ]),
                    ...(phoneSearch
                      ? [
                          {
                            phones: phoneSearch,
                          },
                        ]
                      : []),
                    {
                      emails: {
                        _or: search.flatMap((str) => [
                          { email: { _ilike: `%${str}%` } },
                        ]),
                      },
                    },
                  ],
                },
              ],
            };
          },
        }}
        customFilter={{
          filters: [
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Эксперт',
              field: 'userId',
              source: 'user',
              selection: 'id fullName',
              itemText: 'fullName',
              multiple: true,
              filter: (value) =>
                value.length ? { userId: { _in: value } } : {},
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Реферер клиент',
              field: 'refererClientId',
              source: 'client',
              selection: 'id fullName',
              itemText: 'fullName',
              fetchAll: false,
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Реферер эксперт',
              field: 'refererUserId',
              source: 'user',
              selection: 'id fullName',
              itemText: 'fullName',
            },
            {
              type: 'autocomplete',
              name: 'Источник',
              field: 'source',
              options: leadSources.map((x) => ({ value: x, text: x })),
              multiple: true,
              filter: (value) =>
                value.length ? { source: { _in: value } } : {},
            },
            {
              type: 'autocomplete',
              name: 'Теги',
              options: tags
                .filter((x) => x.entity === 'client')
                .map((x) => ({ value: x.id, text: x.name })),
              field: 'tagId',
              multiple: true,
              filter: (value) =>
                value.length ? { tags: { tagId: { _in: value } } } : {},
            },
          ],
        }}
      />
      <NewClientDialog
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        onSubmit={(item) => {
          tableRef.current?.reload();
          navigate(`/clients/${item.id}`);
        }}
      />
    </TablePageLayout>
  );
}
