import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { DeleteRestore } from 'mdi-material-ui';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { EstatesTable } from 'components/EstatesTable';
import {
  Finishing,
  HouseTypes,
  RoomsNumbers,
  Statuses,
  Types,
} from 'constants/estate';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export default function UserEstates() {
  const { user, hasPermission } = useContext(UserContext);
  const { tags, estateStatuses } = useContext(SettingsContext);
  const [selectedTab, setSelectedTab] = useState<string>('active');

  const tableRef = useRef<DataTableExRef>(null);
  const viewAchive = hasPermission(Permissions.ESTATES_ARCHIVE);
  const viewAllEstates = hasPermission(Permissions.ESTATES_VIEW_ALL);
  const viewAllEstatesSearch = hasPermission(
    Permissions.ESTATES_VIEW_ALL_SEARCH,
  );

  const filterColumns = useCallback(
    (columns: HasuraDataTableColumnDef[]) => {
      const ignore = [
        ...(!['assignments', 'secondary'].includes(selectedTab)
          ? ['sellingCommissionSum']
          : []),
      ];
      if (selectedTab === 'deleted' && viewAchive) {
        return [
          ...columns.filter((x) => !ignore.includes(x.field)),
          {
            field: 'recover',
            type: 'icon-button',
            icon: <DeleteRestore />,
            tooltip: 'Восстановить',
            selector: false,
            tabs: ['deleted'],
            onClick: async (
              row,
              {
                configuration: { hasura },
                notifications: { showConfirm, showAlert },
              },
            ) => {
              if (
                !(await showConfirm({
                  title: 'Восстановить объект?',
                  accept: 'Восстановить',
                  cancel: 'Отмена',
                }))
              ) {
                return;
              }

              await hasura.request({
                type: 'mutation',
                action: 'update',
                source: 'estate',
                where: { id: { _eq: row.id } },
                set: { isRemoved: false },
              });

              showAlert('Восстановлено', 'success');
              await tableRef.current!.reload();
            },
          } as HasuraDataTableColumnDef,
        ];
      }

      return columns.filter((x) => !ignore.includes(x.field));
    },
    [selectedTab, viewAchive],
  );

  return (
    <TablePageLayout
      title="Объекты"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Добавить
        </Button>
      }
    >
      <EstatesTable
        id="estates-table"
        ref={tableRef}
        skeletonRowsCount={50}
        filterColumns={filterColumns}
        disableRemovedFilter
        deletable={selectedTab !== 'deleted'}
        tabsFilter={{
          tabs: [
            {
              id: 'active',
              label: 'Все',
              filter: { isRemoved: { _eq: false } },
            },
            {
              id: 'assignments',
              label: 'Переуступка',
              filter: {
                status: { _in: [Statuses.FOR_SALE, Statuses.DEPOSIT_MADE] },
                type: { _neq: 'secondary' },
                isRemoved: { _eq: false },
              },
            },
            {
              id: 'secondary',
              label: 'Вторичка',
              filter: {
                status: { _eq: Statuses.FOR_SALE },
                type: { _eq: 'secondary' },
                isRemoved: { _eq: false },
              },
            },
            ...(viewAchive
              ? [
                  {
                    id: 'deleted',
                    label: 'Удаленные',
                    filter: { isRemoved: { _eq: true } },
                  },
                ]
              : []),
          ],
          tabsProps: {
            onChange(e, value) {
              setSelectedTab(value);
            },
          },
        }}
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по ID, ЖК, адресу, ФИО собственника',
          },
          minLength: 0,
          filter: (search) => ({
            _and: [
              ...(!viewAllEstates &&
              (!viewAllEstatesSearch || search[0].length < 3)
                ? [
                    {
                      clients: {
                        client: {
                          participants: {
                            userId: { _eq: user.id },
                          },
                        },
                      },
                    },
                  ]
                : []),
              ...(search[0].length > 0
                ? [
                    {
                      _or: [
                        ...search.flatMap((str) => [
                          ...(Number.isInteger(parseInt(str, 10)) &&
                          str.length < 7
                            ? [{ id: { _eq: parseInt(str, 10) } }]
                            : []),
                          {
                            address: { _ilike: `%${str}%` },
                            residentialComplexId: { _isNull: true },
                          },
                        ]),
                        {
                          residentialComplex: {
                            _or: [
                              ...search.map((str) => ({
                                name: { _ilike: `%${str}%` },
                              })),
                              {
                                addresses: {
                                  _or: search.map((str) => ({
                                    address: { _ilike: `%${str}%` },
                                  })),
                                },
                              },
                            ],
                          },
                        },
                        {
                          clients: {
                            _or: [
                              {
                                isOwner: { _eq: true },
                                client: {
                                  _or: search.map((str) => ({
                                    fullName: { _ilike: `%${str}%` },
                                  })),
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ]
                : []),
            ],
          }),
        }}
        customFilter={{
          filters: [
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Жилой комплекс',
              field: 'residentialComplexId',
              source: 'residentialComplex',
              selection: 'id name',
              itemText: 'name',
              multiple: true,
              filter: (value) =>
                value.length ? { residentialComplexId: { _in: value } } : {},
            },
            {
              type: 'autocomplete',
              name: 'Кол. комнат',
              field: 'roomsNumber',
              options: RoomsNumbers,
              multiple: true,
              filter: (value) =>
                value.length ? { roomsNumber: { _in: value } } : {},
            },
            {
              type: 'autocomplete',
              name: 'Тип дома',
              field: 'houseType',
              options: HouseTypes,
              multiple: true,
              filter: (value) =>
                value.length ? { houseType: { _in: value } } : {},
            },
            {
              type: 'autocomplete',
              name: 'Отделка',
              field: 'finishing',
              options: Finishing,
              multiple: true,
              filter: (value) =>
                value.length ? { finishing: { _in: value } } : {},
            },
            {
              type: 'autocomplete',
              name: 'Статус',
              field: 'status',
              options: estateStatuses,
              multiple: true,
              filter: (value) =>
                value.length ? { status: { _in: value } } : {},
            },
            {
              type: 'autocomplete',
              name: 'Тип договора',
              field: 'type',
              options: Types,
              multiple: true,
              filter: (value) => ({ type: { _in: value } }),
              width: 500,
            },
            {
              type: 'autocomplete',
              name: 'Застройщик',
              field: 'developer',
              mode: 'hasura',
              source: 'residentialComplex',
              preset: 'suggestion',
            },
            {
              type: 'autocomplete',
              name: 'Теги',
              options: tags
                .filter((x) => x.entity === 'estate')
                .map((x) => ({ value: x.id, text: x.name })),
              field: 'tagId',
              filter: (value) =>
                value.length ? { tags: { tagId: { _in: value } } } : {},
              multiple: true,
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Клиент',
              field: 'client',
              source: 'client',
              selection: 'id fullName',
              itemText: 'fullName',
              filter: (value) => ({
                client: { clientId: { _eq: value } },
              }),
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Реферер',
              field: 'referrer',
              source: 'user',
              selection: 'id fullName',
              itemText: 'fullName',
              filter: (value) => ({
                client: { clientUserId: { _eq: value } },
              }),
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Эксперт',
              field: 'user',
              source: 'user',
              selection: 'id fullName',
              itemText: 'fullName',
              multiple: true,
              filter: (value) =>
                value.length
                  ? {
                      user: { userId: { _in: value } },
                    }
                  : {},
            },
          ],
        }}
      />
    </TablePageLayout>
  );
}
