import {
  FormGetter,
  SubmitButton,
  NotificationsContext,
  BaseDataTable,
  BaseDataTableColumnDef,
  FormPageLayout,
  Grid,
} from '@kirz/mui-admin';
import { Box, Button, Typography } from '@mui/material';
// @ts-ignore
import { Parser } from 'json2csv';
import { DownloadOutline, ListStatus, Play } from 'mdi-material-ui';
import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DatabaseQueryParameterType } from 'constants/other';

import QueryParameterInput, {
  QueryParameterInputType,
} from './QueryParameterInput';

export default function QueryResult() {
  const routeParams = useParams() as Record<string, string>;
  const queryId = parseInt(routeParams.id, 10);
  const { showAlert, showConfirm } = useContext(NotificationsContext);
  const nameRef = useRef<string>('');
  const parametersRef = useRef<QueryParameterInputType[]>();
  const [columns, setColumns] = useState<BaseDataTableColumnDef[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  const exportCsv = async () => {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(rows);

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=windows-1252,${encodeURIComponent(csv)}`,
    );
    element.setAttribute('download', `${nameRef.current}.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <FormPageLayout
      source="databaseQuery"
      breadcrumbs={[
        { text: 'Запросы', icon: ListStatus, href: '/queries' },
        (item) => ({ text: item.name, copyOnClick: true }),
      ]}
      actionContent={null}
      breadcrumbsDeps={[]}
      defaultRoute="/queries"
      hideSaveButton
      allowUnsavedExit
      formProps={{
        dirtySubmit: false,
      }}
      formFetcherProps={{
        onSelection: (selections) => [
          ...selections,
          'name',
          `
          parameters (where: {isRemoved:{ _eq: false }}, orderBy: {key: ASC}) {
            id
            inputName: key
            name
            multiple
            remoteItemTextColumns
            remoteItemValueColumn
            remoteTable
            required
            type
          }
        `,
        ],
      }}
      formSubmitterProps={{
        resetAfterSubmit: false,
        showSuccessAlert: false,
        mode: 'rest',
        url: () => ({ method: 'POST', url: '/database/query' }),
        preSubmit(item) {
          return {
            parameters: Object.fromEntries(
              parametersRef.current!.map((x) => [
                x.inputName,
                x.type === DatabaseQueryParameterType.BOOLEAN
                  ? item[`parameter_${x.inputName}`] || false
                  : item[`parameter_${x.inputName}`],
              ]),
            ),
            queryId,
          };
        },
        onSubmit(items: any[]) {
          if (!items.length) {
            showAlert('Ничего не найдено', 'warning');
            return;
          }

          const headers = Object.keys(items[0]);
          setColumns(
            headers.map((x) => ({
              headerName: x,
              field: x,
              flex: 1,
              minWidth: 100,
            })),
          );
          setRows(items.map((x, idx) => ({ _id: idx, ...x })));
        },
        onError(error) {
          if (error?.response?.data?.message) {
            showConfirm({
              title: 'Ошибка',
              text: error?.response?.data?.message,
              accept: 'ОК',
            });
          }
        },
      }}
    >
      <Grid container sx={{ m: 0 }}>
        <FormGetter
          names={['parameters', 'name']}
          onChange={({ parameters, name }) => {
            nameRef.current = name;
            parametersRef.current = parameters;
          }}
          render={({ parameters }) =>
            parameters?.map((x: any) => (
              <QueryParameterInput key={x.id} {...x} />
            ))
          }
        />
      </Grid>
      <Grid container sx={{ m: 0, mt: 2, flex: 1 }}>
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minHeight: 400,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Результаты запроса</Typography>
            <SubmitButton
              variant="contained"
              endIcon={<Play />}
              grid={false}
              sx={{ ml: 4 }}
              activateOnDirty={false}
            >
              Выполнить
            </SubmitButton>
            <Button
              onClick={exportCsv}
              disabled={!rows.length}
              endIcon={<DownloadOutline />}
              sx={{ ml: 2 }}
            >
              Выгрузить
            </Button>
          </Box>
          {!!columns.length && (
            <BaseDataTable
              id="query-results-table"
              columns={columns}
              rows={rows}
              editable={false}
              deletable={false}
              sx={{ mx: -2, borderColor: 'transparent' }}
              getRowId={(x) => x._id}
            />
          )}
        </Grid>
      </Grid>
    </FormPageLayout>
  );
}
