import {
  AutocompleteInput,
  ConditionalInput,
  DateInput,
  FormGetter,
  FormInput,
  DataTableEx,
  FormTab,
} from '@kirz/mui-admin';
import { Box, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';

import { InstallmentTypes } from 'constants/estate';

export default function DelayedTab(props: { dealId: number }) {
  const { dealId } = props;

  return (
    <FormGetter
      names={['paymentForm', 'isFixed']}
      render={(x) => {
        const required = x.paymentForm === 'delayed';
        const readOnly = !!x.isFixed;

        const inputProps = {
          required,
          readOnly,
        };

        return (
          <FormTab
            tab="installment"
            grid={false}
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
          >
            {(isVisible) => (
              <>
                <Grid container sx={{ m: 0, flexBasis: 'auto !important' }}>
                  <AutocompleteInput
                    name="installmentType"
                    label="Тип рассрочки"
                    md={4}
                    options={InstallmentTypes}
                    {...inputProps}
                  />
                  <FormInput
                    name="installmentFirstPayment"
                    label="Первый взнос"
                    md={4}
                    type="number"
                    {...inputProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₽</InputAdornment>
                      ),
                    }}
                  />
                  <ConditionalInput
                    deps={['installmentType']}
                    variants={[
                      {
                        if: (y) => y.installmentType === 'uniform',
                        input: (
                          <FormInput
                            name="installmentInterval"
                            label="Повторять каждые"
                            md={4}
                            type="number"
                            {...inputProps}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  мес.
                                </InputAdornment>
                              ),
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                  <ConditionalInput
                    deps={['installmentType']}
                    variants={[
                      {
                        if: (y) => y.installmentType === 'uniform',
                        input: (
                          <FormInput
                            name="installmentPayment"
                            label="Сумма платежа"
                            md={4}
                            type="number"
                            {...inputProps}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  ₽
                                </InputAdornment>
                              ),
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </Grid>

                {isVisible && (
                  <Box
                    sx={{
                      mt: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <FormGetter
                      names={['installmentType']}
                      render={(x) =>
                        x.installmentType === 'individual' && (
                          <DataTableEx
                            id="estate-installments-schedule-table"
                            source="dealInstallmentSchedule"
                            columns={[
                              {
                                field: 'date',
                                headerName: 'Дата',
                                type: 'date',
                              },
                              {
                                field: 'sum',
                                headerName: 'Сумма платежа',
                                type: 'number',
                                flex: 1,
                              },
                            ]}
                            title="График платажей"
                            selectProps={{
                              filter: { dealId: { _eq: dealId } },
                            }}
                            sortBy={{ field: 'date', sort: 'desc' }}
                            formTitle={(isNew) =>
                              isNew ? 'Добавить дату' : 'Редактирование'
                            }
                            formDialogProps={{
                              formSubmitterProps: {
                                preSubmit: (item) => ({ ...item, dealId }),
                              },
                            }}
                          >
                            <DateInput
                              name="date"
                              label="Дата платежа"
                              required
                              md={6}
                            />
                            <FormInput
                              name="sum"
                              label="Сумма платежа"
                              required
                              type="number"
                              md={6}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    ₽
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </DataTableEx>
                        )
                      }
                    />
                  </Box>
                )}
              </>
            )}
          </FormTab>
        );
      }}
    />
  );
}
