import { FormGetter, FormInput, FormSetter } from '@kirz/mui-admin';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CircleEditOutline } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';

import { ComputedField } from 'components/ComputedField';
import { EstateActualPriceHistoryDialog } from 'components/EstateActualPriceHistoryDialog';
import { ManualWithComputedField } from 'components/ManualWithComputedField';
import { RoleGuard } from 'components/RoleGuard';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { useSettings } from 'hooks/useSettings';
import { AmountInput } from 'shared/inputs';

import { computedInputProps } from './shared/computedInputProps';

type Props = {
  estateId: number;
};

export default function ActualPriceSection({ estateId }: Props) {
  const { hasPermission } = useContext(UserContext);
  const isClient = hasPermission(Permissions.IS_CLIENT);
  const { estateDefaultCommission } = useSettings();

  const [
    isActualPriceHistoryDialogOpened,
    setIsActualPriceHistoryDialogOpened,
  ] = useState(false);

  return (
    <FormGetter
      names={['isSeller']}
      render={({ isSeller }) => {
        return (
          <Grid container sx={{ m: 0 }}>
            {(!isSeller || !isClient) && (
              <FormInput
                {...AmountInput}
                label="Актуальная стоимость"
                name="actualPrice"
                readOnly
                xs={12}
                md={3}
                lg={3}
                xl={2}
                formFetcherValueResolver={null}
                formSubmitterValueResolver={null}
                InputProps={{
                  startAdornment: !isClient && (
                    <InputAdornment position="start">
                      <Tooltip title="Редактировать">
                        <IconButton
                          sx={{ m: -1 }}
                          onClick={() => {
                            setIsActualPriceHistoryDialogOpened(true);
                          }}
                        >
                          <CircleEditOutline />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  endAdornment: AmountInput.InputProps?.endAdornment,
                }}
              />
            )}

            <RoleGuard permission={{ not: Permissions.IS_CLIENT }}>
              <ManualWithComputedField
                md={3}
                lg={3}
                xl={2}
                {...AmountInput}
                name="defaultCommission"
                label="Комиссия"
                selectors={['actualPrice']}
                computedField={(x) =>
                  (x.actualPrice ?? 0) * (estateDefaultCommission / 100)
                }
              />

              <ComputedField
                {...computedInputProps('adsPrice', 'Рекламная стоимость')}
              />
            </RoleGuard>

            <FormSetter
              render={(setValue) => (
                <EstateActualPriceHistoryDialog
                  estateId={estateId}
                  open={isActualPriceHistoryDialogOpened}
                  onClose={(newActualPrice) => {
                    setIsActualPriceHistoryDialogOpened(false);
                    setValue('actualPrice', newActualPrice);
                  }}
                />
              )}
            />
          </Grid>
        );
      }}
    />
  );
}
