import {
  FormTab,
  AttachmentsZone,
  Grid,
  ConfigurationContext,
  FormGetter,
} from '@kirz/mui-admin';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material';
import { OpenInNew } from 'mdi-material-ui';
import React, { useContext, useEffect, useState } from 'react';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { api } from 'services/api';

type DocumentTemplate = {
  id: number;
  name: string;
};

type DownloadDocumentButtonProps = ButtonProps & {
  template: DocumentTemplate;
  dealId: number;
  sellerId?: number;
  buyerId?: number;
  estateId?: number;
};

export function DownloadDocumentButton({
  template,
  dealId,
  sellerId,
  buyerId,
  estateId,
  ...props
}: DownloadDocumentButtonProps) {
  return (
    <Button
      variant="contained"
      href={`/api/v1/files/document/${template.name}.docx?deal_id=${dealId}&seller_id=${sellerId}&buyer_id=${buyerId}&estate_id=${estateId}&document_id=${template.id}`}
      target="_blank"
      sx={{ minWidth: 170 }}
      {...(props as any)}
    >
      {template.name}
    </Button>
  );
}

export default function FilesTab(props: { dealId: number }) {
  const { dealId } = props;
  const { hasura } = useContext(ConfigurationContext);
  const { user, hasPermission } = useContext(UserContext);
  const [isVariablesLoaded, setIsVariablesLoaded] = useState(false);
  const [availableVariables, setAvailableVariables] = useState<
    | {
        name: string;
        url: string | null;
        variables: {
          key: string;
          available: boolean;
        }[];
      }[]
    | null
  >(null);
  const [documentTemplates, setDocumentTemplates] = useState<
    | {
        id: number;
        name: string;
      }[]
    | null
  >(null);
  const isClient = hasPermission(Permissions.IS_CLIENT);

  useEffect(() => {
    (async () => {
      if (isClient) {
        return;
      }

      const items = await hasura.request({
        type: 'query',
        selection: 'id name',
        source: 'documentTemplate',
        orderBy: {
          createdAt: 'DESC',
        },
        where: {
          type: { _eq: 'deal' },
        },
      });

      setDocumentTemplates(items);
    })();
  }, []);

  return (
    <FormTab tab="files" grid={false}>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Файлы
      </Typography>
      <FormGetter
        names={['paymentForm', 'client', 'seller', 'isBuyer']}
        render={({ isBuyer, ...x }) => {
          return (
            <AttachmentsZone
              name="dealId"
              entityId={dealId}
              {...(isClient && {
                showSections: true,
              })}
              attachmentsTypes={[
                { value: 'contract', text: 'Договор' },
                ...(isBuyer || !isClient
                  ? [
                      { value: 'mortgage', text: 'Документы по ипотеке' },
                      { value: 'files', text: 'Другие файлы' },
                    ]
                  : []),
              ]}
              gridProps={{
                xl: 4,
                lg: 6,
                md: 6,
                sm: 6,
                xs: 12,
              }}
            />
          );
        }}
      />

      {!isClient && (
        <FormGetter
          names={['sellerId', 'clientId', 'estateId']}
          onChange={async (x) => {
            if (
              x.sellerId === null ||
              x.clientId === null ||
              x.estateId === null
            ) {
              setIsVariablesLoaded(true);
              return;
            }

            if (
              x.sellerId == null ||
              x.clientId == null ||
              x.estateId == null
            ) {
              return;
            }

            const result = (await api.getDocument({
              dealId,
              sellerId: x.sellerId,
              buyerId: x.clientId,
              estateId: x.estateId,
              documentId: -1,
              dryRun: true,
            })) as {
              filled: string[];
              missed: string[];
            };

            const groups = Object.entries(
              [
                ...result.filled.map((x) => ({ key: x, available: true })),
                ...result.missed.map((x) => ({ key: x, available: false })),
              ]
                .map((x) => {
                  const [prefix] = x.key.split('.');

                  return {
                    ...x,
                    ...(() => {
                      if (prefix === 'продавец') {
                        return {
                          entity: 'Продавец',
                          field: 'sellerId',
                          table: 'clients',
                        };
                      }

                      if (prefix === 'покупатель') {
                        return {
                          entity: 'Покупатель',
                          field: 'clientId',
                          table: 'clients',
                        };
                      }

                      if (prefix === 'объект') {
                        return {
                          entity: 'Объект',
                          field: 'estateId',
                          table: 'estates',
                        };
                      }

                      if (prefix === 'жк') {
                        return {
                          entity: 'ЖК',
                          table: null,
                        };
                      }

                      if (prefix === 'сделка') {
                        return {
                          entity: 'Сделка',
                          table: null,
                        };
                      }

                      if (prefix === 'эксперт') {
                        return {
                          entity: 'Эксперт',
                          table: null,
                        };
                      }

                      return {
                        entity: 'Другие',
                        table: null,
                      };
                    })(),
                  };
                })
                .sort((a, b) => a.key.localeCompare(b.key))
                .reduce((acc, x) => {
                  acc[x.entity] = acc[x.entity] || [];
                  acc[x.entity].push(x);

                  return acc;
                }, {} as Record<string, any[]>),
            ).map(([key, items]) => ({
              name: key as string,
              url: items[0].table
                ? `/${items[0].table}/${x[items[0].field as any]}`
                : null,
              variables: items,
            }));

            setAvailableVariables(groups);
          }}
          render={(y) => {
            const isDisabled =
              y.sellerId == null ||
              y.clientId == null ||
              y.estateId == null ||
              !availableVariables;

            return (
              <Grid container sx={{ m: 0, mt: 4 }}>
                <Grid
                  xs={12}
                  md={4}
                  container
                  sx={{ alignContent: 'flex-start' }}
                >
                  <Grid xs={12}>
                    <Typography variant="subtitle1">Договоры</Typography>
                  </Grid>
                  {documentTemplates?.map((template) => (
                    <Grid xs={12} sm="auto" key={template.id}>
                      <DownloadDocumentButton
                        disabled={isDisabled}
                        dealId={dealId}
                        sellerId={y.sellerId}
                        buyerId={y.clientId}
                        estateId={y.estateId}
                        template={template}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid xs={12} md={8} container>
                  <Grid xs={12}>
                    <Typography variant="subtitle1">Переменные</Typography>
                  </Grid>
                  <Grid xs={12} sx={{}}>
                    {!availableVariables && !isVariablesLoaded ? (
                      <Box sx={{ display: 'flex', ml: 4, mt: 1 }}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : isDisabled ? (
                      <Box sx={{ display: 'flex', ml: 4, mt: 0 }}>
                        <Typography color="red" sx={{ opacity: 0.8 }}>
                          Продавец, покупатель или объект не заполнены
                        </Typography>
                      </Box>
                    ) : (
                      <Box>
                        {availableVariables.map((x) => (
                          <Alert severity="info" key={x.name} sx={{ mb: 1 }}>
                            <AlertTitle>
                              {x.url ? (
                                <Link href={x.url} sx={{ color: 'inherit' }}>
                                  {x.name}
                                  <OpenInNew
                                    sx={{ fontSize: 14, ml: 1, mb: '-2px' }}
                                  />
                                </Link>
                              ) : (
                                x.name
                              )}
                            </AlertTitle>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                ml: -0.8,
                              }}
                            >
                              {x.variables.map((variable) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 0.8,
                                    my: 0.1,
                                  }}
                                  key={variable.key}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: variable.available
                                        ? 'inherit'
                                        : '#b73a3a',
                                    }}
                                  >
                                    {variable.key}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Alert>
                        ))}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      )}
    </FormTab>
  );
}
