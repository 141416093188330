import {
  AutocompleteInput,
  FormGetter,
  FormInput,
  FormPageLayout,
  HasuraAutocompleteRef,
  HasuraSelector,
  HasuraSelectorRef,
  NotificationsContext,
  OpenInNewInputAdorement,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { PhoneMessageOutline } from 'mdi-material-ui';
import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AudioPlayer } from 'components/AudioPlayer';
import { NewClientDialog } from 'components/NewClientDialog';
import { NewDealDialog } from 'components/NewDealDialog';
import { TagsGroup } from 'components/TagsGroup';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export function Call() {
  const routeParams = useParams() as Record<string, string>;
  const callId = parseInt(routeParams.id, 10);
  const { tags } = useContext(SettingsContext);
  const { user } = useContext(UserContext);
  const clientSelectorRef = useRef<HasuraSelectorRef>(null);
  const [isNewClientDialogOpened, setIsNewClientDialogOpened] = useState(false);
  const { showAlert, showPrompt } = useContext(NotificationsContext);
  const dealsAutocompleteRef = useRef<HasuraAutocompleteRef>(null);

  return (
    <>
      <FormPageLayout
        source="call"
        breadcrumbs={[
          { text: 'Звонки', icon: PhoneMessageOutline, href: '/calls' },
          (item) => ({
            text: `Звонок #${item.id} от ${dayjs(item.createdAt).format(
              'DD.MM.YYYY HH:mm',
            )}`,
            copyOnClick: true,
          }),
        ]}
        breadcrumbsDeps={['createdAt']}
        defaultRoute="/calls"
        tagsSlot={
          <Box sx={{ ml: 1 }}>
            <FormGetter
              names={['tags']}
              render={({ tags: callTags }) =>
                callTags && (
                  <TagsGroup ids={callTags} size="small" placeholder={false} />
                )
              }
            />
          </Box>
        }
      >
        <HasuraSelector selection="clientPhone" />
        <HasuraSelector selection="createdAt" />
        <HasuraSelector selection="recordLink" />
        {/* <HasuraSelector selection="dealId" /> */}

        <HasuraSelector
          ref={clientSelectorRef}
          name="clientByPhone"
          selection="clientsByPhone {clientId}"
          resolveValue={({ clientsByPhone }) => {
            return {
              clientByPhone: clientsByPhone?.[0],
            };
          }}
        />
        <Grid container sx={{ m: 0 }}>
          <FormGetter
            names={['recordLink']}
            render={(x) => (
              <Grid xs={12} sx={{ mb: 3, height: '68px', overflow: 'hidden' }}>
                {x.recordLink && (
                  <AudioPlayer callId={callId} url={x.recordLink} />
                )}
                {x.recordLink === null && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    Запись звонка отсутствует
                  </Box>
                )}
              </Grid>
            )}
          />
          <FormGetter
            names={['clientByPhone']}
            render={(x) =>
              x?.clientByPhone?.clientId ? (
                <AutocompleteInput
                  value={x.clientByPhone.clientId}
                  name="clientId"
                  mode="hasura"
                  source="client"
                  label="Клиент"
                  selection="id fullName"
                  itemText="fullName"
                  md={3}
                  fetchAll={false}
                  required
                  readOnly
                  inputProps={{
                    InputProps: {
                      startAdornment: (
                        <OpenInNewInputAdorement
                          field="clientId"
                          baseUrl="/clients"
                          entityId={x.clientByPhone.clientId}
                        />
                      ),
                    },
                  }}
                />
              ) : (
                <FormGetter
                  names={['clientByPhone']}
                  render={(x) =>
                    !x?.clientByPhone?.clientId && (
                      <Grid
                        md={3}
                        sx={{ alignItems: 'center', display: 'flex' }}
                      >
                        <Button
                          variant="contained"
                          startIcon={<Add />}
                          onClick={() => setIsNewClientDialogOpened(true)}
                        >
                          Создать клиента
                        </Button>
                      </Grid>
                    )
                  }
                />
              )
            }
          />
          <FormGetter
            names={['clientByPhone']}
            onChange={(newValue, prevValue) => {
              // setValue('dealId', null);
            }}
            render={(x) => {
              const clientId = x?.clientByPhone?.clientId;

              // if (!clientId) {
              //   return null;
              // }

              return (
                <AutocompleteInput
                  controlRef={dealsAutocompleteRef}
                  name="dealId"
                  mode="hasura"
                  source="deal"
                  label="Сделка"
                  selection="id name"
                  itemText={(x) =>
                    `Сделка #${x.id}${x.name ? `: ${x.name}` : ''}`
                  }
                  md={3}
                  fetchAll
                  disabled={!clientId}
                  inputProps={{
                    InputProps: {
                      startAdornment: (
                        <OpenInNewInputAdorement
                          field="dealId"
                          baseUrl="/deals"
                          entityId={x.dealId}
                        />
                      ),
                    },
                  }}
                  filter={{
                    _or: [
                      { clientId: { _eq: clientId } },
                      { sellerId: { _eq: clientId } },
                      { clients: { clientId: { _eq: clientId } } },
                    ],
                  }}
                  disableFetch={clientId === undefined}
                  onFetch={(items) => {
                    return items;
                  }}
                  addNewOption={{
                    text: 'Добавить сделку',
                    async onClick() {
                      const values = await showPrompt({
                        title: 'Новая сделка',
                        // form: (
                        //   <FormInput
                        //     name="address"
                        //     label="Адрес"
                        //     required
                        //     autoFocus
                        //   />
                        // ),
                        dialog: NewDealDialog,
                        accept: 'Создать',
                        width: 500,
                        formSubmitterProps: {
                          source: 'estateAddress',
                          preSubmit: (item) => ({
                            ...item,
                            residentialComplexId: x.residentialComplex.id,
                          }),
                          selection: ['id'],
                        },
                      });

                      await dealsAutocompleteRef.current?.refetch();
                    },
                  }}
                />
              );
            }}
          />

          <AutocompleteInput
            name="userId"
            mode="hasura"
            source="user"
            label="Сотрудник"
            selection="id fullName"
            itemText="fullName"
            md={3}
            readOnly
          />
          <AutocompleteInput
            name="tags"
            label="Теги"
            multiple
            md={3}
            options={tags
              .filter((x) => !x.systemType && x.entity === 'call')
              .map((x) => ({ value: x.id, text: x.name }))}
            formFetcherValueResolver={{
              source: 'callTag',
              selection: 'tagId',
              filter: { callId: { _eq: callId } },
              resolveItems: (items) => items.map((x) => x.tagId),
            }}
            formSubmitterValueResolver={{
              type: 'hasura-many-to-many',
              source: 'callTag',
              foreignKey: 'tagId',
              entityId: { callId, companyId: user.companyId },
            }}
          />
          <FormInput name="comment" label="Комментарий" multiline rows={4} />
          <FormInput
            name="extractedText"
            label="Распознанный текст"
            multiline
            rows={20}
            readOnly
          />

          <FormGetter
            names={['clientPhone']}
            render={(x) => (
              <NewClientDialog
                open={isNewClientDialogOpened}
                onClose={() => setIsNewClientDialogOpened(false)}
                formProps={{
                  defaultValues: {
                    phone: x.clientPhone,
                  },
                }}
                onSubmit={(item) => {
                  clientSelectorRef.current?.refetch();
                }}
              />
            )}
          />
        </Grid>
      </FormPageLayout>
    </>
  );
}
