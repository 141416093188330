import { ConfigurationContext, formatPhone } from '@kirz/mui-admin';
import { IconButton, Snackbar } from '@mui/material';
import { Close } from 'mdi-material-ui';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { ActiveCallDialog } from 'components/ActiveCallDialog';
import { UserContext } from 'contexts';
import { api } from 'services/api';

export function ActiveCallHandler() {
  const { hasura } = useContext(ConfigurationContext);
  const [waitingCalls, setWaitingCalls] = useState<any[]>([]);
  const [activeCall, setActiveCall] = useState<any>(null);
  const [isActiveCallOpened, setIsActiveCallOpened] = useState(false);
  const collapsedCalls = useRef<number[]>([]);

  const { user } = useContext(UserContext);

  const stopCall = async () => {
    await hasura.request({
      type: 'mutation',
      source: 'call',
      action: 'update',
      where: { id: { _eq: activeCall.id } },
      set: { isCollapsed: true },
    });

    collapsedCalls.current = [...collapsedCalls.current, activeCall.id];
    setActiveCall(null);
  };

  useEffect(() => {
    const onNext = ({ data: { call } }: any) => {
      setWaitingCalls(call);
    };

    const unsubscribe = api.hasuraWsClientInstance!.subscribe(
      {
        query: `subscription GetItem ($where: CallBoolExp!) {
          call(where: $where, orderBy: {createdAt: ASC}) {
            id
            direction
            clientPhone
          }
        }`,
        variables: {
          where: {
            isCollapsed: { _eq: false },
            userId: { _eq: user.id },
            activeStatus: { _in: ['INCOMING', 'OUTGOING', 'ACCEPTED'] },
          },
        },
      },
      {
        next: onNext,
        error: () => {},
        complete: () => {},
      },
    );

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const calls = waitingCalls.filter(
      (x: any) => !collapsedCalls.current.includes(x.id),
    );

    if (activeCall && collapsedCalls.current.includes(activeCall.id)) {
      setActiveCall(null);
      return;
    }

    if (activeCall || !calls.length) {
      return;
    }

    setActiveCall(calls[0]);
    setIsActiveCallOpened(true);
  }, [waitingCalls, activeCall]);

  if (!activeCall) {
    return null;
  }

  return (
    <>
      <ActiveCallDialog
        open={isActiveCallOpened}
        callId={activeCall.id}
        clientPhone={activeCall.clientPhone}
        callDirection={activeCall.direction}
        onClose={() => {
          if (!waitingCalls.find((x) => x.id === activeCall.id)) {
            setActiveCall(null);
          }
          setIsActiveCallOpened(false);
        }}
        stopCall={stopCall}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isActiveCallOpened}
        disableWindowBlurListener
        onClick={() => {
          setIsActiveCallOpened(true);
        }}
        message={`Звонок от ${formatPhone(activeCall.clientPhone)}`}
        sx={{
          cursor: 'pointer',
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={async (e) => {
              e.stopPropagation();

              await stopCall();
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
