import {
  DataTable,
  AutocompleteInput,
  FormInput,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
  useNavigate,
  FormDialog,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export function Leads() {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { leadSources } = useContext(SettingsContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const tableRef = useRef<DataTableRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'createdAt', headerName: 'Дата создания', type: 'dateTime' },
      { field: 'fullName', headerName: 'ФИО', flex: 1 },
      {
        field: 'phone',
        headerName: 'Телефон',
        type: 'phone',
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'E-mail',
        type: 'email',
        flex: 1,
      },
      {
        field: 'source',
        headerName: 'Источник',
        flex: 1,
      },
      {
        field: 'comment',
        headerName: 'Комментарий',
        flex: 1,
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Лиды"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedItem(null);
            setIsEditModalOpened(true);
          }}
        >
          Добавить
        </Button>
      }
    >
      <DataTable
        id="leads-table"
        ref={tableRef}
        mode="hasura"
        source="lead"
        columns={columns}
        editable={{
          onEdit: (row) => {
            setSelectedItem(row);
            setIsEditModalOpened(true);
          },
        }}
        deletable
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по имени и номеру телефона',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { fullName: { _ilike: `%${str}%` } },
              { phone: { _ilike: `%${str.replace(/[+ \-()]/g, '')}%` } },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              type: 'autocomplete',
              name: 'Источник',
              field: 'source',
              options: leadSources.map((x) => ({ value: x, text: x })),
              multiple: true,
              filter: (value) =>
                value.length ? { source: { _in: value } } : {},
            },
          ],
        }}
      />
      <FormDialog
        source="lead"
        {...(selectedItem && { entityId: selectedItem.id })}
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        title={!selectedItem ? 'Новый лид' : 'Сделать клиентом'}
        maxWidth="md"
        onSubmit={(item) => {
          tableRef.current?.reload();
          navigate(`/clients/${item.id}`);
        }}
        formProps={{
          defaultValues: {
            userId: user.id,
          },
        }}
        formFetcherProps={{
          selector: { userId: null, funnelId: null },
        }}
        formSubmitterProps={{
          mode: 'rest',
          url: () => ({ url: '/leads', method: 'POST' }),
        }}
      >
        <FormInput name="secondName" label="Фамилия" md={4} />
        <FormInput name="firstName" label="Имя" md={4} required />
        <FormInput name="middleName" label="Отчество" md={4} />
        <FormInput name="phone" label="Телефон" md={4} type="phone" required />
        <FormInput name="email" label="E-mail" md={4} type="email" />
        <AutocompleteInput
          name="source"
          label="Источник"
          md={4}
          options={leadSources.map((x) => ({ value: x, text: x }))}
        />
        <AutocompleteInput
          name="userId"
          mode="hasura"
          source="user"
          label="Эксперт"
          selection="id fullName"
          itemText="fullName"
          md={6}
          required
        />
        <AutocompleteInput
          name="funnelId"
          mode="hasura"
          source="funnel"
          label="Воронка"
          selection="id name"
          itemText="name"
          md={6}
          required
        />
        <FormInput
          name="comment"
          label="Комментарий"
          md={6}
          type="text"
          multiline
          rows={3}
        />
        <FormInput
          name="adInfo"
          label="Рекламная информация"
          md={6}
          multiline
          rows={3}
        />
      </FormDialog>
    </TablePageLayout>
  );
}
