import {
  AutocompleteInput,
  ConfigurationContext,
  FormInput,
  FormTab,
  FormGetter,
  FormSetter,
  ConditionalInput,
  HasuraSelector,
  CheckboxInput,
  NotificationsContext,
  HasuraAutocompleteRef,
  HasuraSelectorRef,
  OpenInNewInputAdorement,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Star, StarOutline } from 'mdi-material-ui';
import React, { useContext, useRef, useState } from 'react';

import { DealsTable } from 'components/DealsTable';
import { NewDealDialog } from 'components/NewDealDialog';
import { RoleGuard } from 'components/RoleGuard';
import {
  EntityCounterparties,
  HouseTypes,
  Sources,
  Types,
} from 'constants/estate';
import { FunnelType } from 'constants/funnels';
import { numberToQuarter } from 'constants/other';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';
import { SaleQueueForm } from 'pages/ResidentialComplex';

export default function MainTab(props: { estateId: number }) {
  const { estateId } = props;
  const { user, hasPermission } = useContext(UserContext);
  const { hasura } = useContext(ConfigurationContext);
  const { tags, estateStatuses } = useContext(SettingsContext);
  const { showAlert, showPrompt } = useContext(NotificationsContext);
  const [newDealDialogData, setNewDealDialogData] = useState<{
    type: 'add' | 'delete';
    clientId?: number;
  } | null>(null);
  const clientsTableRef = useRef<DataTableExRef>(null);
  const dealsTableRef = useRef<DataTableExRef>(null);
  const residentialComplexes = useRef<any[]>([]);
  const addressesAutocompleteRef = useRef<HasuraAutocompleteRef>(null);
  const saleQueuesAutocompleteRef = useRef<HasuraAutocompleteRef>(null);
  const mainUserSelectorRef = useRef<HasuraSelectorRef>(null);
  const isClient = hasPermission(Permissions.IS_CLIENT);

  return (
    <>
      <FormTab
        tab="main"
        grid={false}
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
      >
        {(isVisible) => (
          <Grid container sx={{ m: 0, flexBasis: 'auto !important' }}>
            <RoleGuard permission={{ not: Permissions.IS_CLIENT }}>
              <HasuraSelector
                ref={mainUserSelectorRef}
                name="mainUser"
                selection="mainUser: client {user {id fullName}}"
                resolveValue={(item) => ({ mainUser: item.mainUser?.user })}
              />
            </RoleGuard>
            <FormSetter
              render={(setValue) => (
                <>
                  <AutocompleteInput
                    name="residentialComplexId"
                    mode="hasura"
                    source="residentialComplex"
                    label="Жилой комплекс"
                    selection="id name developer houseType floorsNumberFrom floorsNumberTo"
                    itemText="name"
                    md={4}
                    {...(!isClient && {
                      inputProps: {
                        InputProps: {
                          startAdornment: (
                            <OpenInNewInputAdorement
                              field="residentialComplexId"
                              baseUrl="/residential-complexes"
                            />
                          ),
                        },
                      },
                    })}
                    onFetch={(items) => {
                      residentialComplexes.current = items;
                      return items;
                    }}
                    onChange={(_, residentialComplexId) => {
                      const residentialComplex =
                        residentialComplexes.current.find(
                          (x) => x.id === residentialComplexId,
                        );

                      setValue('saleQueueId', null);
                      setValue('estateAddressId', null);
                      setValue('residentialComplex', residentialComplex);
                    }}
                  />
                  <ConditionalInput
                    deps={['residentialComplex']}
                    variants={[
                      {
                        if: (x) => !x.residentialComplex,
                        input: (
                          <FormInput
                            name="address"
                            label="Адрес"
                            md={8}
                            required
                          />
                        ),
                      },
                      {
                        if: (x) => !!x.residentialComplex,
                        input: (x) => (
                          <AutocompleteInput
                            controlRef={addressesAutocompleteRef}
                            name="estateAddressId"
                            mode="hasura"
                            source="estateAddress"
                            label="Адрес"
                            selection="id address"
                            itemText="address"
                            md={8}
                            required
                            filter={{
                              residentialComplexId: {
                                _eq: x.residentialComplex?.id || -1,
                              },
                            }}
                            addNewOption={{
                              text: 'Добавить адрес',
                              async onClick() {
                                const values = await showPrompt({
                                  title: 'Новый адрес',
                                  form: (
                                    <FormInput
                                      name="address"
                                      label="Адрес"
                                      required
                                      autoFocus
                                    />
                                  ),
                                  accept: 'Создать',
                                  width: 500,
                                  formSubmitterProps: {
                                    source: 'estateAddress',
                                    preSubmit: (item) => ({
                                      ...item,
                                      residentialComplexId:
                                        x.residentialComplex.id,
                                    }),
                                    selection: ['id'],
                                  },
                                });

                                if (values) {
                                  addressesAutocompleteRef.current?.refetch();
                                  setValue('estateAddressId', values.id);
                                }
                              },
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            />
            <ConditionalInput
              deps={['residentialComplex']}
              variants={[
                {
                  if: (x) => !x.residentialComplex,
                  input: (
                    <AutocompleteInput
                      name="developer"
                      label="Застройщик"
                      mode="hasura"
                      source="residentialComplex"
                      preset="suggestion"
                      md={4}
                    />
                  ),
                },
                {
                  if: (x) => !!x.residentialComplex,
                  input: ({ residentialComplex }) => (
                    <FormInput
                      value={residentialComplex.developer}
                      name="developer"
                      label="Застройщик"
                      readOnly
                      md={4}
                    />
                  ),
                  keepMounted: false,
                },
              ]}
            />
            <ConditionalInput
              deps={['residentialComplex']}
              variants={[
                {
                  if: (x) => !x.residentialComplex,
                  input: (
                    <AutocompleteInput
                      name="houseType"
                      label="Тип дома"
                      options={HouseTypes}
                      md={3}
                    />
                  ),
                },
                {
                  if: (x) => !!x.residentialComplex,
                  input: ({ residentialComplex }) => (
                    <AutocompleteInput
                      value={residentialComplex.houseType}
                      name="houseType"
                      label="Тип дома"
                      options={HouseTypes}
                      readOnly
                      md={3}
                    />
                  ),
                  keepMounted: false,
                },
              ]}
            />
            <ConditionalInput
              deps={['residentialComplex']}
              variants={[
                {
                  if: (x) => !x.residentialComplex,
                  input: (
                    <FormInput
                      name="houseFloors"
                      label="Этажность дома"
                      md={2}
                      type="number"
                      min={0}
                      max={100}
                    />
                  ),
                },
                {
                  if: (x) => !!x.residentialComplex,
                  input: ({ residentialComplex }) => (
                    <FormInput
                      name="houseFloors"
                      value={(() => {
                        const { floorsNumberFrom, floorsNumberTo } =
                          residentialComplex;
                        // eslint-disable-next-line max-len
                        if (
                          floorsNumberFrom &&
                          floorsNumberTo &&
                          floorsNumberFrom !== floorsNumberTo
                        ) {
                          return `${floorsNumberFrom}-${floorsNumberTo}`;
                        }

                        if (floorsNumberFrom || floorsNumberTo) {
                          return `${floorsNumberFrom || floorsNumberTo}`;
                        }
                      })()}
                      label="Этажность дома"
                      md={2}
                      type="number"
                      min={0}
                      max={100}
                      readOnly
                    />
                  ),
                  keepMounted: false,
                },
              ]}
            />

            <RoleGuard permission={{ not: Permissions.IS_CLIENT }}>
              <AutocompleteInput
                name="type"
                label="Тип"
                options={Types}
                required
                md={3}
              />
              <FormGetter
                names={['mainUser', 'secondaryExpertId']}
                render={({ mainUser, secondaryExpertId }) => (
                  <>
                    <FormInput
                      value={mainUser?.fullName || null}
                      name="mainUserName"
                      label={
                        secondaryExpertId
                          ? 'Эксперт-реферер'
                          : 'Основной эксперт'
                      }
                      md={4}
                      readOnly
                    />
                    <AutocompleteInput
                      name="secondaryExpertId"
                      mode="hasura"
                      source="user"
                      label={
                        secondaryExpertId
                          ? 'Основной эксперт'
                          : 'Назначенный эксперт'
                      }
                      selection="id fullName"
                      itemText="fullName"
                      md={4}
                    />
                  </>
                )}
              />
              <AutocompleteInput
                name="entityCounterparty"
                label="Юр. лицо контрагент"
                options={EntityCounterparties}
                md={4}
              />
              <FormSetter
                render={(setValue) => (
                  <ConditionalInput
                    deps={['residentialComplex']}
                    variants={[
                      {
                        if: (x) => !!x.residentialComplex,
                        input: ({ residentialComplex }) => (
                          <AutocompleteInput
                            controlRef={saleQueuesAutocompleteRef}
                            name="saleQueueId"
                            mode="hasura"
                            source="estateSaleQueue"
                            label="Очередь продаж"
                            selection="serialNumber releaseQuarter releaseYear keysQuarter keysYear"
                            itemText={(item) =>
                              `№${item.serialNumber}: ${numberToQuarter(
                                item.releaseQuarter,
                              )}/${item.releaseYear}-${numberToQuarter(
                                item.keysQuarter,
                              )}/${item.keysYear}`
                            }
                            md={4}
                            required
                            filter={{
                              residentialComplexId: {
                                _eq: residentialComplex?.id || -1,
                              },
                            }}
                            addNewOption={{
                              async onClick() {
                                const values = await showPrompt({
                                  title: 'Новая очередь продаж',
                                  form: <SaleQueueForm />,
                                  accept: 'Создать',
                                  width: 500,
                                  formSubmitterProps: {
                                    source: 'estateSaleQueue',
                                    preSubmit: (item) => ({
                                      ...item,
                                      residentialComplexId:
                                        residentialComplex.id,
                                    }),
                                    selection: ['id'],
                                  },
                                });

                                if (values) {
                                  saleQueuesAutocompleteRef.current?.refetch();
                                  setValue('saleQueueId', values.id);
                                }
                              },
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                )}
              />

              <AutocompleteInput
                name="status"
                label="Статус"
                options={estateStatuses}
                required
                md={3}
              />
              <FormGetter
                names={['residentialComplex']}
                render={({ residentialComplex }) => (
                  <AutocompleteInput
                    name="tags"
                    label="Теги"
                    multiple
                    md={residentialComplex ? 5 : 9}
                    options={tags
                      .filter((x) => !x.systemType && x.entity === 'estate')
                      .map((x) => ({ value: x.id, text: x.name }))}
                    formFetcherValueResolver={{
                      source: 'estateTag',
                      selection: 'tagId',
                      filter: { estateId: { _eq: estateId } },
                      resolveItems: (items) => items.map((x) => x.tagId),
                    }}
                    formSubmitterValueResolver={{
                      type: 'hasura-many-to-many',
                      source: 'estateTag',
                      foreignKey: 'tagId',
                      entityId: { estateId, companyId: user.companyId },
                    }}
                  />
                )}
              />

              <Grid xs={12} md={8}>
                {isVisible && (
                  <DataTableEx
                    id="estate-clients-table"
                    ref={clientsTableRef}
                    title={
                      (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>Клиенты</Typography>
                          <Tooltip title="Добавить собственника">
                            <IconButton
                              onClick={() => {
                                setNewDealDialogData({
                                  type: 'add',
                                });
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      ) as any
                    }
                    source="clientEstate"
                    addButton={false}
                    inline
                    sortBy={{ field: 'id', sort: 'desc' }}
                    columns={[
                      {
                        field: 'isOwner',
                        headerName: 'Собственник?',
                        type: 'icon-button',
                        // eslint-disable-next-line react/no-unstable-nested-components
                        icon: (row) =>
                          !row.isOwner ? (
                            <StarOutline />
                          ) : (
                            <Star htmlColor="#FFCD3C" />
                          ),
                        width: 120,
                        sortable: true,
                        onSort(sort) {
                          return [{ isOwner: sort }];
                        },
                      },
                      {
                        field: 'id',
                        headerName: 'ID',
                        width: 70,
                        onSort(sort) {
                          return [{ clientId: sort }];
                        },
                      },
                      {
                        field: 'fullName',
                        headerName: 'ФИО',
                        flex: 1,
                        onSort(sort) {
                          return [{ client: { fullName: sort } }];
                        },
                      },
                      {
                        field: 'favouritePhone',
                        headerName: 'Телефон',
                        type: 'phone',
                        onSort(sort) {
                          return [{ client: { favouritePhone: sort } }];
                        },
                      },
                      {
                        field: 'delete',
                        headerName: ' ',
                        selector: false,
                        disableColumnMenu: true,
                        //  type: 'icon-button',
                        // eslint-disable-next-line react/no-unstable-nested-components
                        renderCell: (row) => (
                          <IconButton
                            disabled={!row.row.isOwner}
                            onClick={() => {
                              setNewDealDialogData({
                                type: 'delete',
                                clientId: row.row.id,
                              });
                            }}
                            sx={{
                              '& .idle': { display: 'flex' },
                              '& .hover': { display: 'none' },
                              '&:hover .idle': { display: 'none' },
                              '&:hover .hover': { display: 'flex' },
                            }}
                          >
                            <>
                              <DeleteIcon className="idle" />
                              <DeleteForeverIcon
                                className="hover"
                                color="error"
                              />
                            </>
                          </IconButton>
                        ),
                        width: 70,
                        sortable: false,
                      },
                    ]}
                    disableRemovedFilter
                    selectProps={{
                      filter: {
                        estateId: { _eq: estateId },
                      },
                      onSelection() {
                        return [
                          'isOwner',
                          'client { id fullName favouritePhone }',
                        ];
                      },
                      onFetch: (items) =>
                        items.map(({ id, isOwner, client }) => ({
                          ...client,
                          isOwner,
                          clientId: client.id,
                          id: client.id,
                        })),
                    }}
                    formTitle={() => 'Смена собственника'}
                    skeletonRowsCount={3}
                    editable={{
                      link: (row) => `/clients/${row.clientId}`,
                    }}
                    deletable={false}
                    formDialogProps={{
                      formProps: { defaultValues: { isOwner: false } },
                      formSubmitterProps: {
                        preSubmit: async ({ clientId, ...rest }) => {
                          const [existingRelatedClient] = await hasura.request({
                            source: 'clientEstate',
                            selection: 'id',
                            where: {
                              estateId: { _eq: estateId },
                              clientId: { _eq: clientId },
                            },
                            limit: 1,
                          });

                          if (existingRelatedClient) {
                            showAlert('Клиент уже существует', 'error');
                            return null;
                          }

                          return {
                            ...rest,
                            estateId,
                            clientId,
                          };
                        },
                        onSubmit() {
                          mainUserSelectorRef.current?.refetch();
                        },
                      },
                    }}
                  />
                )}
              </Grid>

              <Grid container xs={12} md={4} sx={{ m: 0 }}>
                <AutocompleteInput
                  name="source"
                  label="Источник"
                  options={Sources}
                />
                <FormInput
                  name="comment"
                  label="Комментарий"
                  multiline
                  rows={8}
                />
              </Grid>

              <Grid xs={12}>
                {isVisible && (
                  <DealsTable
                    id="estate-deals-table"
                    ref={dealsTableRef}
                    title="Сделки"
                    sx={{ minHeight: 400, mx: -2, borderColor: 'transparent' }}
                    disableInitialization={estateId == null}
                    deletable={hasPermission(Permissions.DEALS_DELETE)}
                    selectProps={{
                      filter: { estateId: { _eq: estateId } },
                    }}
                    sortBy={{ field: 'dealDate', sort: 'desc' }}
                    formDialogProps={{
                      formProps: {
                        defaultValues: { estateId },
                      },
                      ...({ disableClient: true } as any),
                    }}
                  />
                )}
              </Grid>
            </RoleGuard>
          </Grid>
        )}
      </FormTab>

      <NewDealDialog
        open={!!newDealDialogData}
        title="Смена собственника"
        onClose={() => {
          setNewDealDialogData(null);
        }}
        formProps={{
          defaultValues: {
            systemType: FunnelType.TECHNICAL,
            estateId,
            ...(newDealDialogData?.type === 'delete' && {
              sellerId: newDealDialogData.clientId,
            }),
          },
        }}
        disableEstate
        disableSystemType
        requireClient={newDealDialogData?.type === 'add'}
        requireSeller={newDealDialogData?.type === 'delete'}
        onSubmit={() => {
          // clientsTableRef.current?.reload();
          // dealsTableRef.current?.reload();
        }}
      />
    </>
  );
}
