import {
  DateInput,
  Form,
  FormGetter,
  FormSetter,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableExRef,
  NotificationsContext,
  ConfigurationContext,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  CheckBold,
  ChevronLeft,
  ChevronRight,
  CloseThick,
} from 'mdi-material-ui';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TasksTable } from 'components/TasksTable';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

type AssignedTaskActionButtonProps = {
  type: 'accept' | 'decline';
  taskId: number;
  onFinish?: () => void;
};

function AssignedTaskActionButton({
  type,
  taskId,
  onFinish,
}: AssignedTaskActionButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { showConfirm, showAlert } = useContext(NotificationsContext);
  const { hasura } = useContext(ConfigurationContext);

  return (
    <Tooltip title={type === 'accept' ? 'Принять' : 'Отклонить'}>
      <IconButton
        onClick={async () => {
          const result = await showConfirm({
            title: type === 'accept' ? 'Принять задачу?' : 'Отклонить задачу?',
            accept: type === 'accept' ? 'Принять' : 'Отклонить',
            cancel: 'Отмена',
          });

          if (!result) {
            return;
          }

          try {
            setIsLoading(true);

            await hasura.request({
              type: 'mutation',
              action: 'update',
              source: 'task',
              where: { id: { _eq: taskId } },
              set: {
                isConfirmed: type === 'accept',
              },
            });

            showAlert(
              type === 'accept' ? 'Задача принята' : 'Задача отклонена',
              type === 'accept' ? 'success' : undefined,
            );
            onFinish?.();
          } finally {
            setIsLoading(false);
          }
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : type === 'accept' ? (
          <CheckBold htmlColor="green" />
        ) : (
          <CloseThick htmlColor="red" />
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Tasks() {
  const theme = useTheme();
  const { tags } = useContext(SettingsContext);
  const { user, hasPermission } = useContext(UserContext);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDateTo, setSelectedDateTo] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>('active');

  const tableRef = useRef<DataTableExRef>(null);
  const viewAllTasks = hasPermission(Permissions.TASKS_VIEW_ALL);

  const tableFilter = useMemo(
    () => ({
      _and: [
        ...(selectedDate
          ? (() => {
              if (selectedTab === 'assigned') {
                return [];
              }

              if (selectedDateTo) {
                return [
                  {
                    date: { _lte: selectedDateTo },
                  },
                  {
                    date: { _gte: selectedDate },
                  },
                ];
              }

              if (
                selectedDate === dayjs().format('YYYY-MM-DD') &&
                selectedTab === 'active'
              ) {
                return [
                  {
                    date: { _lte: selectedDate },
                  },
                ];
              }

              return [
                {
                  date: { _eq: selectedDate },
                },
              ];
            })()
          : []),
        ...(!(
          viewAllTasks &&
          (selectedTab === 'all' || selectedTab === 'assigned')
        )
          ? [
              {
                userId: { _eq: user.id },
              },
            ]
          : []),
      ],
    }),
    [selectedDate, selectedDateTo, selectedTab, viewAllTasks, user],
  );

  const filterColumns = useCallback(
    (columns: HasuraDataTableColumnDef[]) => {
      const ignore = ['result'];

      if (selectedTab === 'assigned' || selectedTab === 'completed') {
        return [
          ...columns
            .filter(
              (x) =>
                !(selectedTab === 'assigned' && x.field === 'creator') &&
                !(selectedTab === 'completed' && x.field === 'user'),
            )
            .filter((x) => !ignore.includes(x.field))
            .flatMap((x) =>
              x.field === 'task'
                ? [
                    x,
                    {
                      headerName: 'Результат',
                      field: 'result',
                      placeholder: false,
                      ...(x.width && { width: x.width }),
                      ...(x.minWidth && { minWidth: x.minWidth }),
                      ...(x.flex && { flex: x.flex }),
                      renderCell: x.renderCell,
                    } as any,
                  ]
                : x,
            ),
          ...(selectedTab === 'assigned'
            ? [
                {
                  headerName: 'Действия',
                  field: 'actions',
                  width: 120,
                  selector: 'userId clientId dealId',
                  renderCell: ({ row: { id, userId, clientId, dealId } }) => {
                    return (
                      <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                        <AssignedTaskActionButton
                          type="decline"
                          taskId={id}
                          onFinish={() => {
                            tableRef.current?.openFormDialog({
                              userId,
                              clientId,
                              dealId,
                            });
                            tableRef.current?.reload();
                          }}
                        />
                        <AssignedTaskActionButton
                          type="accept"
                          taskId={id}
                          onFinish={() => {
                            tableRef.current?.reload();
                          }}
                        />
                      </Box>
                    );
                  },
                } as HasuraDataTableColumnDef,
              ]
            : []),
        ];
      }

      return columns.filter(
        (x) => x.field !== 'result' || selectedTab === 'all',
      );
    },
    [selectedTab],
  );

  return (
    <TablePageLayout
      title="Задачи"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Добавить
        </Button>
      }
    >
      <TasksTable
        id="tasks-table"
        ref={tableRef}
        selectProps={{
          filter: tableFilter,
        }}
        skeletonRowsCount={50}
        disableInitialization={!selectedDate}
        filterColumns={filterColumns}
        tabsFilter={{
          tabs: [
            {
              id: 'active',
              label: 'Мои задачи',
              filter: {
                isFinished: { _eq: false },
              },
            },
            {
              id: 'assigned',
              label: 'Назначенные',
              filter: {
                creatorId: { _eq: user.id },
                userId: { _neq: user.id },
                isConfirmed: { _isNull: true },
              },
            },
            ...(viewAllTasks
              ? [
                  {
                    id: 'all',
                    label: 'Все задачи',
                  },
                ]
              : []),
            {
              id: 'completed',
              label: 'Завершенные',
              filter: {
                isFinished: { _eq: true },
                userId: { _eq: user.id },
              },
            },
          ],
          tabsProps: {
            onChange(e, value) {
              setSelectedTab(value);
            },
          },
          leftSlot: (
            <Box sx={{ mr: 1, ml: -1 }}>
              <Form
                sx={{ width: { xs: 200, md: 500 } }}
                defaultValues={{
                  date: dayjs().format('YYYY-MM-DD'),
                }}
                grid={false}
                dense
                persistStateMode={{ type: 'query', queryPrefix: 'df_' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ml: -0.5,
                  }}
                >
                  <FormSetter
                    render={(setValue) => (
                      <FormGetter
                        onChange={(item) => {
                          setSelectedDate(item.date);
                          setSelectedDateTo(item.toDate);
                        }}
                        render={(item) => (
                          <>
                            <Tooltip title="Предыдущий день">
                              <IconButton
                                onClick={() =>
                                  setValue(
                                    'date',
                                    dayjs(item.date)
                                      .add(-1, 'day')
                                      .format('YYYY-MM-DD'),
                                  )
                                }
                                size="small"
                              >
                                <ChevronLeft
                                  htmlColor={theme.palette.brand.main}
                                  sx={{ opacity: 0.5 }}
                                />
                              </IconButton>
                            </Tooltip>
                            <DateInput
                              label="Дата (от)"
                              name="date"
                              xs={12}
                              grid={false}
                              clearable={false}
                              sx={{ mx: 0.01 }}
                            />
                            <Tooltip title="Следующий день">
                              <IconButton
                                onClick={() =>
                                  setValue(
                                    'date',
                                    dayjs(item.date)
                                      .add(1, 'day')
                                      .format('YYYY-MM-DD'),
                                  )
                                }
                                size="small"
                              >
                                <ChevronRight
                                  htmlColor={theme.palette.brand.main}
                                  sx={{ opacity: 0.5 }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Предыдущий день">
                              <span>
                                <IconButton
                                  disabled={!item.toDate}
                                  onClick={() =>
                                    setValue(
                                      'toDate',
                                      dayjs(item.toDate)
                                        .add(-1, 'day')
                                        .format('YYYY-MM-DD'),
                                    )
                                  }
                                  sx={{ display: { xs: 'none', md: 'flex' } }}
                                  size="small"
                                >
                                  <ChevronLeft
                                    htmlColor={theme.palette.brand.main}
                                    sx={{ opacity: 0.5 }}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <DateInput
                              label="Дата (до)"
                              name="toDate"
                              xs={12}
                              grid={false}
                              clearable
                              sx={{
                                ml: 0.1,
                                display: { xs: 'none', md: 'flex' },
                              }}
                            />
                            <Tooltip title="Следующий день">
                              <span>
                                <IconButton
                                  disabled={!item.toDate}
                                  onClick={() =>
                                    setValue(
                                      'toDate',
                                      dayjs(item.toDate)
                                        .add(1, 'day')
                                        .format('YYYY-MM-DD'),
                                    )
                                  }
                                  sx={{ display: { xs: 'none', md: 'flex' } }}
                                  size="small"
                                >
                                  <ChevronRight
                                    htmlColor={theme.palette.brand.main}
                                    sx={{ opacity: 0.5 }}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </>
                        )}
                      />
                    )}
                  />
                </Box>
              </Form>
            </Box>
          ),
        }}
        searchFilter={{
          position: 'tabs',
          inputProps: {
            placeholder: 'Поиск по ID, тексту задачи или клиенту',
          },
          filter: (search) => {
            const ids = search
              .filter(
                (str) => Number.isInteger(parseInt(str, 10)) && str.length < 7,
              )
              .map((str) => parseInt(str, 10));

            return {
              _or: [
                ...(ids.length ? [{ id: { _in: ids } }] : []),
                ...search.flatMap((str) => [
                  { client: { fullName: { _ilike: `%${str}%` } } },
                ]),
                ...search.flatMap((str) => [{ task: { _ilike: `%${str}%` } }]),
              ],
            };
          },
        }}
        customFilter={{
          filters: [
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Исполнитель',
              field: 'userId',
              source: 'user',
              selection: 'id fullName',
              itemText: 'fullName',
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Постановщик',
              field: 'creatorId',
              source: 'user',
              selection: 'id fullName',
              itemText: 'fullName',
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Клиент',
              field: 'clientId',
              filter(clientId) {
                return { dynamicClient: { clientId: { _eq: clientId } } };
              },
              source: 'client',
              selection: 'id fullName',
              itemText: 'fullName',
              fetchAll: false,
            },
            {
              type: 'autocomplete',
              name: 'Теги',
              options: tags
                .filter((x) => x.entity === 'task')
                .map((x) => ({ value: x.id, text: x.name })),
              field: 'tagId',
              filter: (value) =>
                value.length ? { tags: { tagId: { _in: value } } } : {},
              multiple: true,
            },
            {
              type: 'autocomplete',
              name: 'Статус задачи',
              options: [
                { text: 'В работе', value: 'in-work' },
                { text: 'Завершена', value: 'completed' },
                { text: 'Просрочена', value: 'expired' },
              ],
              field: 'status',
              multiple: true,
              filter: (values) => {
                const getFilter = (value: any) => {
                  if (value === 'in-work') {
                    return { isFinished: { _eq: false } };
                  }

                  if (value === 'completed') {
                    return { isFinished: { _eq: true } };
                  }

                  if (value === 'expired') {
                    return {
                      isFinished: { _eq: false },
                      _or: [
                        {
                          time: { _isNull: false },
                          datetime: { _lt: new Date().toISOString() },
                        },
                        {
                          time: { _isNull: true },
                          date: { _lt: dayjs().startOf('day') },
                        },
                      ],
                    };
                  }

                  throw new Error('Not implemented');
                };

                return values.length ? { _or: values.map(getFilter) } : {};
              },
            },
          ],
        }}
      />
    </TablePageLayout>
  );
}
