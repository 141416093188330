import {
  DataTableEx,
  DateInput,
  FormGetter,
  FormInput,
  FormSetter,
  FormTab,
  HasuraSelector,
} from '@kirz/mui-admin';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { OpenInNew } from 'mdi-material-ui';
import React, { useContext } from 'react';

import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import { ComputedField } from 'components/ComputedField';
import { RoleGuard } from 'components/RoleGuard';
import { DealClientTypeValue } from 'constants/other';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { AmountInput, PercentInput, readOnlyProps } from 'shared/inputs';

import { TabTitle } from '../TabTitle';
import ActualPriceSection from './ActualPriceSection';
import PriceTabProfitSection from './PriceTabProfitSection';
import { computedInputProps } from './shared/computedInputProps';

export default function PriceTab(props: { estateId: number }) {
  const { estateId } = props;
  const { user, hasPermission } = useContext(UserContext);
  const isClient = hasPermission(Permissions.IS_CLIENT);

  const priceFields = [
    'purchaseDealId',
    'purchasePrice',
    'contractPurchasePrice',
    'purchaseDate',
    'actualPrice',
    ...(isClient ? [] : ['yearlyIncome', 'totalIncome', 'adsPrice', 'tax']),
  ];

  return (
    <FormTab tab="price" grid={false}>
      <HasuraSelector
        name={[...priceFields, ...(isClient ? ['isSeller'] : [])]}
        selection={`price { ${[
          ...priceFields,
          'purchaseDeal { sellerId clients { type clientId } price { totalIncome yearlyIncome } }',
        ].join(' ')} }`}
        resolveValue={({ price: priceParam }) => {
          const { purchaseDeal, ...price } = priceParam ?? {};

          if (isClient) {
            return {
              ...price,
              totalIncome: purchaseDeal?.price?.totalIncome,
              yearlyIncome: purchaseDeal?.price?.yearlyIncome,
              isSeller:
                purchaseDeal &&
                (purchaseDeal.sellerId === user.id ||
                  !!purchaseDeal.clients.find(
                    (x: any) =>
                      [DealClientTypeValue.SELLER].includes(x.type) &&
                      x.clientId === user.id,
                  )),
            };
          }

          return {
            ...price,
            lastDealTotalIncome: purchaseDeal?.price?.totalIncome,
            lastDealYearlyIncome: purchaseDeal?.price?.yearlyIncome,
          };
        }}
        refetchOnSubmit
      />
      <FormGetter
        names={['purchaseDealId', 'purchasePrice']}
        render={({ purchaseDealId, purchasePrice }) => (
          <>
            <TabTitle
              title="Последняя сделка"
              dealId={purchaseDealId}
              tab="price"
            />
            <Grid container sx={{ m: 0 }}>
              <DateInput
                name="purchaseDate"
                label="Дата сделки"
                md={3}
                xl={2}
                {...readOnlyProps}
              />
              <FormInput
                label="Сумма сделки"
                name="purchasePrice"
                {...AmountInput}
                md={3}
                xl={2}
                {...readOnlyProps}
              />
              <FormInput
                label="Сумма сделки по договору"
                name="contractPurchasePrice"
                {...AmountInput}
                {...(purchasePrice && {
                  placeholder: purchasePrice.toLocaleString(),
                  InputLabelProps: { shrink: true },
                })}
                md={3}
                xl={2}
                {...readOnlyProps}
              />
              {!isClient && (
                <>
                  <ComputedField
                    {...computedInputProps(
                      'lastDealTotalIncome',
                      'Общий доход',
                    )}
                  />
                  <ComputedField
                    {...computedInputProps(
                      'lastDealYearlyIncome',
                      'Годовой доход',
                      PercentInput,
                    )}
                  />
                </>
              )}
            </Grid>
          </>
        )}
      />
      <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>
        {isClient ? 'Доходность' : 'Актуальная стоимость'}
      </Typography>
      <ActualPriceSection estateId={estateId} />

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 2 }}>
        {!isClient && (
          <Typography variant="subtitle1">Калькулятор доходности</Typography>
        )}
        <RoleGuard permission={{ not: Permissions.IS_CLIENT }}>
          <FormSetter
            render={(setValues) => (
              <Button
                variant="text"
                sx={{ ml: 1, fontSize: '0.75rem', fontWeight: '400' }}
                size="small"
                color="inherit"
                startIcon={<ResetIcon />}
                onClick={() => {
                  setValues('estimatedDealPrice', null, { shouldDirty: true });
                  setValues('contractPrice', null, { shouldDirty: true });
                  setValues('sellerCommission', null, { shouldDirty: true });
                }}
              >
                Вернуть значения по умолчанию
              </Button>
            )}
          />
        </RoleGuard>
      </Box>

      <PriceTabProfitSection estateId={estateId} />

      {!isClient && (
        <FormGetter
          names={[]}
          render={() => (
            <>
              <Box
                sx={[{ display: 'flex', mb: 1, alignItems: 'center', mt: 3 }]}
              >
                <Typography variant="subtitle1">Агрегаторы</Typography>

                <FormGetter
                  names={['funnelId']}
                  render={(x) => (
                    <Link
                      href={`/deals-list?dm_displayMode=report&df_filters=${encodeURIComponent(
                        JSON.stringify({
                          '5_0': estateId,
                          '0_1': dayjs()
                            .subtract(2, 'weeks')
                            .format('YYYY-MM-DD'),
                          '9_2': 3, // Resale
                        }),
                      )}`}
                      sx={{ pb: '2px' }}
                    >
                      <Typography
                        variant="caption"
                        sx={{ ml: 2 }}
                        color="#121828"
                      >
                        <OpenInNew sx={{ fontSize: 12, mr: 0.5, mb: '-2px' }} />
                        Перейти в отчет
                      </Typography>
                    </Link>
                  )}
                />
              </Box>
              <DataTableEx
                id="estate-aggregator-links-table"
                title="Ссылки"
                source="estateAggregatorLink"
                columns={[
                  {
                    field: 'link',
                    headerName: 'Ссылка',
                    flex: 1,
                    renderCell: ({ value }) => (
                      <Box
                        component="a"
                        href={value}
                        target="__blank"
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {value}
                      </Box>
                    ),
                  },
                ]}
                formTitle={(isNew) =>
                  isNew ? 'Добавить ссылку' : 'Редактирование ссылки'
                }
                skeletonRowsCount={2}
                columnHeaderHeight={0}
                inline
                pageSizeOptions={[10000]}
                autoHeight
                slots={{
                  noRowsOverlay: () => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                        opacity: 0.7,
                      }}
                    >
                      Нет данных
                    </Box>
                  ),
                  footer: () => null,
                  columnHeaders: () => null,
                }}
                selectProps={{
                  filter: { estateId: { _eq: estateId } },
                }}
                editable={{
                  columnProps: { width: 40, minWidth: 40 } as any,
                }}
                deletable={{
                  columnProps: { width: 45, minWidth: 45 } as any,
                }}
                formDialogProps={{
                  maxWidth: 'xs',
                  formSubmitterProps: {
                    preSubmit(item) {
                      return {
                        ...item,
                        estateId,
                      };
                    },
                  },
                }}
                sx={{
                  borderColor: 'transparent',
                  mt: -2,
                  maxWidth: '600px',
                }}
              >
                <FormInput name="link" label="Ссылка" required type="url" />
              </DataTableEx>
            </>
          )}
        />
      )}
    </FormTab>
  );
}
